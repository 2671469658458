.icon-list
  margin-left: 3 * $vgu;

  &--text
    // TODO: Why have you decided to use word-break?
    // word-break: break-all;
    min-height: 2 * $vgu;

  &--item
    margin-bottom: $vgu;
    position: relative;
    display: flex;
    min-height: 2 * $vgu;

    &:before
      position: absolute;
      left: -3 * $vgu;
      width: 2 * $vgu;
      text-align: center;
      line-height: 2 * $vgu;
      font-size: 1rem;
      margin-right: 0;

    &.no-bottom-margin
      margin-bottom: 0;

  &--icon
    position: absolute;
    left: -3 * $vgu;
    width: 2 * $vgu;
    height: 2 * $vgu;
    &:before
      position: absolute;
      width: 2 * $vgu;
      text-align: center;
      line-height: 2 * $vgu;
      font-size: 1rem;
      margin-right: 0;

    &__positive-1:before
      color: colorPalette('positive', 1)
    &__negative-1
      color: colorPalette('negative', 1)

  &__wrap
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    .icon-list--item
      margin-right: 3 * $vgu;


  &__horizontal
    flex-direction: row;
    // Compensate for the fact that each individual item has a big margin right (will wrap early)
    margin-right: -3 * $vgu

    .icon-list
      &--item
        display: inline-block;
        flex: 0 auto;
        margin-right: 6 * $vgu;

.horizontal-list
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  &__small
    font-size: $vgu;
    line-height: $vgu;
  &__item
    margin-right: $vgu;
    margin-bottom: $vgu;
    &__small
      p
        line-height: 1.7 * $vgu;
        font-size: 1.2 * $vgu;
      span
        &:before
          line-height: $vgu;
          font-size: $vgu;
    p
      margin-bottom: 0;
    span
      &::before
        margin-right: 0.5 * $vgu;
        font-size: 1.5 * $vgu;
    &:last-of-type
      margin-right: 0;

owner-input
  width: 100%;

pxn-loading, .loading-container
  display: flex;
  align-self: center;
  flex: 1 0 auto;
  color: $c--black;
  align-items: center;
  font-size: 2 * $vgu;
  .loading
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    &--spinner
      animation: loading-spinner 1s infinite;
      display: flex;
      margin-bottom: $vgu;
      &::before
        font-family: 'entypo';
        margin: 0;
        content: '\1f504'
    &--text
      line-height: 1.2em;
      margin-bottom: 0.2em;
  &.hidden
    flex: 0 0 0;

@keyframes loading-spinner
  0%
    transform: rotate(0);
    transform-origin: center;
  100%
    transform: rotate(-360deg);
    transform-origin: center;
