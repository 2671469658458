.playlist-container
  flex-direction: row-reverse;
  +browser(firefox)
    height: 0;
    min-height: 100%;
  +browser(edge)
    flex-grow: 1;
  .playlist-helper
    max-height: "calc(100vh - %s - %s)" % ($contentHeaderHeight $vgu);
    flex-direction: column-reverse;
    +when(md)
      flex-direction: column;
    +browser(edge)
      height: 0;
      min-height: 100%;
    &--filters, &--about
      flex-direction: column;
    &--about
      flex: 0 1 auto;
  .playlists
    margin-top: -1 * $vgu;
    padding-top: $vgu;
    +browser(edge)
      height: 0;
      min-height: 100%;
    +browser(firefox)
      height: 0;
      min-height: 100%;
  .playlist
    flex-direction: row;
    flex: 0 0 auto;
    &-heading
      &--title
        margin-bottom: $vgu;
        align-items: center;
      &--name
        margin-right: $vgu;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      &--count
        display: none;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        +when(md)
          display: block;
      &--button
        margin-right: $vgu;
      pxn-dropdown-menu
        margin-bottom: $vgu;
    &-metadata
      justify-content: space-between;
      &--buttons
        flex-direction: row;
        justify-content: flex-start;
        pxn-button
          &:last-of-type
            margin-right: 0;
    &-channels
      &--list
        flex: 1;
        justify-content: flex-start;
        position: relative;
        &__none
          padding: 2 * $vgu;
          justify-content: center;
          width: 100%;
      &--channel
        flex-direction: row;
        align-items: center;
        cursor: move;
        &__name, &__length
          margin-right: $vgu;
        &__name
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline;
          overflow: hidden;
          width: 100%;
        &__length
          display: flex;
          flex: 0 0 auto;
        &__button
          margin-bottom: $vgu;
      &__placeholder + .playlist-channels--list__none
        display: none;
      &__placeholder
        background: #aaa;
        margin-bottom: $vgu;
        padding: $vgu;
        min-height: 50px;
        align-items: center;
        z-index: 2;
  .dndDraggingSource
    display: none;
