@require '_colours'
.integration

  &__manage
    flex: 1;
    position: relative;
    padding: $vgu;
    padding-bottom: 0;
    margin-bottom: $vgu;
    border: 0.5 * $vgu solid;

    &--needs-reconnect
      border: 0.5 * $vgu dashed colorPalette('warning', 1)

  &--requires-attention-marker
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;

  &--service
    position: absolute;
    top: $vgu;
    left: $vgu;
    width: "calc(100% - %s)" % (2 * $vgu);
    &-logo
      // line-height: $vgu * 2;
      margin-right: $colGutter;
      &::before
        font-size: $vgu * 2;
        margin-right: 0;
    &__beekeeper
      background-color: #fff;
    &__instagram
      background-position: center;
      filter: invert(1);
      // This is the inverted colour value of the
      // calculated border colour.
      border-color: #04851f !important
      &^[-2]--image // &.integration--image
        background-size: 70%;
    &__workplace
      background-position: center;
      filter: invert(1);
      // This is the inverted colour value of the
      // calculated border colour.
      border-color: #83807a !important
      &^[-2]--image // &.integration--image
        background-size: 75%;


  &--image
    width: 5 * $vgu;
    height: 5 * $vgu;
    margin: 3 * $vgu 0 $vgu 0;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    border-width: 2px
    border-style: solid;
    border-color: inherit;

  &--name
    font-weight: bold;
    margin-bottom: $vgu * 0.5;
  &--username
    text-align: center;
    //

  &--username
    color: colourPalette('disabled', 2);
    flex: 1;

  &--delete-button
    margin-bottom: $vgu;
    &:not(:last-of-type)
      margin-right: $vgu !important;

.settings
  &--integration
    align-items: center;
    flex-direction: column;
    &-list
      flex-wrap: wrap;
    &-list-container
      flex-grow: 1;
    &-list__empty
      align-items: center;
      flex-grow: 1;
    &-add
      flex-direction: column;
      integration
        flex: 1;
  &__natural-height
    flex: 0 0 auto;

.offline-email-alert-list
  font-family: 'Montserrat', sans-serif;
  margin: 0.5 * $vgu 0;
  &--summary
    margin-left: 0.2 * $vgu

.external-account
  &--buttons
    flex-direction: column;

.sGenerator
  &--container
    max-height: "calc(100vh - %s - %s)" % ($contentHeaderHeight $vgu);
  &--conn-button
    transition: opacity $anim-duration;
    button
      margin-bottom: 0;
  &--cert-button
    button
      margin-bottom: 0;
  &--static-ip
    &-step-container
      position: relative;
    &-template-link
      position: absolute;
      top: 11.5 * $vgu;
    &-table
      &__body
        font-family: monospace;
      &__invalid
        color: $c--shades['negative'][0];
  &--error-text
    color: white;
    background-color: $c--shades['negative'][0];
    margin: 2 * $vgu;
    margin-bottom: 3 * $vgu;
    margin-top: 0;
    padding: 1 * $vgu;

  &--placeholder-button
    margin-top 3 * $vgu
    margin-bottom 3 * $vgu

  &--download-button
    margin-bottom: 2 * $vgu
    margin-left: 1 * $vgu;
    margin-right: 1 * $vgu;

.unlock-keys
  &--key, &--generated
    margin-right: 1em;
  &--key
    flex: 1 0 auto;
