.content-header
  display: flex;
  background-color: $c--bg-sidebar;
  color: $c--offwhite;
  position: relative;
  height: $contentHeaderHeight;

  &--menu-button
    position: absolute;
    left: $colGutter;
    top: 0.5 * $vgu;
    transform: scale(1.5);
    transition: transform $anim-duration;

    &:active
      transform: scale(1.3);

    button
      color: $c--offwhite;
      transition: color $anim-duration;

    button:focus
      color: $c--pixel-purple;

    +when($sidebarBreakpoint)
      transform: scale(0);

  &--heading
    padding-top: 1 * $vgu;
    text-align: center;

  &--button
    margin-right: $vgu;
    position: static;
    flex-shrink: 0;
    +when(md)
      position: fixed;
      top: $vgu;
      right: $vgu;
    &.new-resource-btn
      +when(md)
        right: 4 * $vgu;
    &.bulk-option-button
      z-index: $z-index__content-overlay;
      &--playlists
        +when(md)
          position: fixed;
          top: $vgu;
          right: 18.2 * $vgu;
      &--channels
        +when(md)
          position: fixed;
          top: $vgu;
          right: 18.7 * $vgu;
      &--slides
        +when(md)
          position: fixed;
          top: $vgu;
          right: 16.7 * $vgu;
      & > .dropdown-menu--items__open
        z-index: $z-index__content-overlay;

    button
      flex: 0 0 auto;

  &--bulk-delete-counter
    position: fixed;
    top: 0;
    left: 0;
    padding: $vgu;
    color: $c--bg-input;
    +when($sidebarBreakpoint)
      left: $sidebarWidth;
    &.next-to-menu
      left: 4 * $vgu;
      +when($sidebarBreakpoint)
        left: $sidebarWidth;
  &--buttons
    // z-index: $z-index__content-overlay;
    justify-content: flex-end;
    flex: 0 0 auto;

// Sidebar - this is a child of a flex row so will automatically fill
// its cross-axis (height).
.sidebar
  //: Ensure we're on the left hand side.;
  order: -1;
  //: The initial style is for when the sidebar is invisible.;
  margin-left: -1 * $sidebarWidth;
  width: $sidebarWidth;
  background-color: $c--bg-sidebar;
  color: $c--txt-sidebar;
  transition: transform $anim-duration;
  position: relative;
  left: $sidebarWidth;
  //: transform translateX(-1 * $sidebarWidth);

  //: After a certain width, the sidebar is always visible;
  +when($sidebarBreakpoint)
    position: static;
    transform: none;
    margin-left: 0;

// This class is applied when the sidebar is video.
.sidebar__open
  //: margin-right 0;
  //: transform translateX($sidebarWidth);

  //: Also need to translate the content area when the sidebar is open;
  & + .flare-container
    transform: translateX($sidebarWidth);

    +when($sidebarBreakpoint)
      transform: none;

nav
  .navigation--decorator
    position: absolute;
    font-size: 1em;
    left: 2.5 * $vgu;
    bottom: 0.5 * $vgu;
    &-icon
      &:before
        font-size: 1em;
        margin-right: 0;

      &.entypo-attention:before
        color: colorPalette('warning', 1);

// Sidebar content styling
.profile-block
  position: relative;
  background-color: $c--accent-1;
  padding-top: 3 * $vgu;
  padding-bottom: 1 * $vgu;
  min-height: 20 * $vgu;

  gravatar-image
    border: 0.5 * $vgu solid $c--accent-1__compl;

  &--logout
    position: absolute;
    top: 4.5 * $vgu;
    left: $vgu;
    button
      padding: 0.5 * $vgu;
  &--user-settings
    position: absolute;
    top: 4.5 * $vgu;
    right: $vgu;
    button
      padding: 0.5 * $vgu;

  &--name
    color: $c--offwhite;
    //: margin-top $vgu;

  &--company
    color: $c--accent-1__compl;

  &--company, &--name
    margin-bottom: 0;
    max-width: $sidebarWidth;
    margin-left: $colGutter;
    margin-right: $colGutter;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 0 auto;

    &__delegate
      color: colorPalette('contrast', 1);

.navigation
  position: relative;
  max-height: "calc(100vh - (20 * %s))" % ($vgu)
  overflow: hidden !important;
  padding-right: $vgu;
  background-color: $c--shades['grey'][0];
  &--category-header
    margin-top: $vgu;
    padding-left: $vgu;
    padding-right: $vgu;
    margin-bottom: 0;
    &__bottom-margin
      margin-bottom: $vgu;

  &--category
    flex: 0 0 auto;
  &--intercom
    color: $c--txt-sidebar;

  &--link
    position: relative;
    display: flex;
    flex: 0 0 auto;
    line-height: 2 * $vgu;
    font-weight: bold;
    font-size: $font-size-base;
    padding: $vgu $vgu $vgu $vgu * 1.5;
    user-select: none;
    cursor: pointer;
    transition: background-color $anim-duration, color $anim-duration;
    overflow: hidden;

    &:active, &__active
      background-color: $c--bg-sidebar__hover;
      color: $c--txt-sidebar__hover;

    &__shared
      background-color: darken($c--bg-sidebar__hover, 40%);
      color: darken($c--txt-sidebar__hover, 40%);

    &:hover
      background-color: darken($c--bg-sidebar__hover, 20%);
      color: darken($c--txt-sidebar__hover, 20%);
      // Manually make the beekeeper icon less dark.
      .pxn-icon-beekeeper:before
        opacity: 0.5;

    &:before
      font-weight: normal;

    .new-indicator
      font-weight: bold;
      color: $c--white;
      text-transform: uppercase;
      text-align: center;
      line-height: 2 * $vgu;
      width: 4 * $vgu;
      display: block;
      background: #2e9b5b;
      background: radial-gradient(#2e9b5b 0%, #79A70A 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      right: $vgu;
      top: $vgu;
      font-size: 0.8rem;
      border-radius: $vgu;

    .updated-indicator
      font-weight: bold;
      color: $c--white;
      text-transform: uppercase;
      text-align: center;
      line-height: 2 * $vgu;
      width: 6 * $vgu;
      display: block;
      background: #7D0079;
      background: radial-gradient(#7D0079 0%, #C900C3 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      right: $vgu;
      top: $vgu;
      font-size: 0.8rem;
      border-radius: $vgu;

    .navigation__secondary &
      padding: $vgu;
      color: $c--txt-sidebar__secondary;
      &:before
        display: block;
        text-align: center;
        font-size: 1.2rem;
        width: 2 * $vgu;
        flex-shrink: 0;
        margin-right: $vgu;
        align-self: center;

      &:hover
        background-color: darken($c--bg-sidebar__hover, 10%);
        color: lighten($c--txt-sidebar__hover, 10%);

      &:active, &__active
        background-color: darken($c--bg-sidebar__hover, 25%);
        color: lighten($c--txt-sidebar__hover, 15%);

  &__secondary
    position: fixed;
    padding: 0 $vgu;
    top: 6 * $vgu;
    bottom: 0;
    transform: translateX(-1 * $sidebarWidth);
    max-height: "calc(100vh - %s)" % ($contentHeaderHeight);


    +when($sidebarBreakpoint)
      position: static;
      transform: none;

    &.sidebar__open
      transform: translateX(0);

      & + .master-detail--detail
        transform: translateX(1 * $sidebarWidth);

        +when($sidebarBreakpoint)
          transform: none;
// Master detail view
.master-detail

  &--master
    margin: $contentPadding__comp;
    width: $sidebarWidth;
    background-color: $c--bg-sidebar__hover;
    left: $vgu;

  &--detail
    transition: transform $anim-duration;
