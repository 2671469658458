@require '_inputs'

$day-size = ($vgu * 3.5);
$day-padding = 2px;

pxn-date-input
  position: relative;
  flex: 1;
  &[clearable="clearable"] pxn-calendar-widget
    top: 5 * $vgu;

pxn-calendar-widget
  display: block;
  width: "calc(%s + %s)" % ($day-size * 7 $day-padding * 2)
  position: absolute;
  right: 0;
  top: 3 * $vgu;

  .pxn-calendar__left-align &
    right: initial;
    left: 0;

pxn-duration-input
  .duration-input
    &--container
      flex: 1
      justify-content: center;
      height: 3 * $vgu;
      margin-bottom: $vgu;
      background-color: $c--bg-input;
      inputborder()
      transition: border-color $anim-duration, background-color $anim-duration;
     &--field
      width: 2rem;
      height: "calc(%s - %s)" % (3*$vgu $input-border-size);
      text-align: center;
      background-color: transparent;
      transition: color $anim-duration;
      border: 0;
      outline: 0;
     &--separator
      pointer-events: none;
      line-height: 3 * $vgu;
      height: "calc(%s - %s)" % (3*$vgu $input-border-size);
      padding: 0 $colGutter * 0.5;
      position: relative;
      top: -3px;
     &__disabled
      background-color: colorPalette('disabled', 1);
      &:active
        // Override border colour from `inputborder()`
        border-color: $c--bg-input !important; // @stylint ignore
       .duration-input--field
        color: colorPalette('disabled', 2);
     &__focussed
      border-color: $c--border-focus;

.date-time-input
  justify-content: center;

  &--date-input
    margin-right: $vgu;

pxn-date-time-input, pxn-date-input
  .clear-input-button
    cursor: pointer;

.date-input
  &--container
    flex: 1;

  &--input
    // inputborder()
    width: 100%;
    flex: 1;
    order: 0;
    text-align: center;

    &__invalid
      border-color: $c--border-negative;

  &--calendar-button
    button
      border-bottom-width: $day-padding
      border-bottom-style: solid;

.time-input
  &--container
    justify-content: center;
    height: 3 * $vgu;
    margin-bottom: $vgu;
    background-color: $c--bg-input;
    inputborder()
    transition: border-color $anim-duration, background-color $anim-duration;

    &__disabled
      background-color: colorPalette('disabled', 1);
      border: none;
      &:active
        // Override border colour from `inputborder()`
        border-color: $c--bg-input !important; // @stylint ignore
    &__invalid
      border-color: $c--border-negative;

      .time-input--input-field
        color: colorPalette('disabled', 2);

  &__focussed
    .time-input--container
      border-color: $c--border-focus;


  &--input-field
    width: 2rem;
    height: "calc(%s - %s)" % (3 * $vgu $input-border-height);
    text-align: center;
    background-color: transparent;
    transition: color $anim-duration;
    border: 0;
    outline: 0;

  &--separator
    pointer-events: none;
    line-height: 3 * $vgu;
    height: "calc(%s - %s)" % (3 * $vgu $input-border-height);
    padding: 0 $colGutter * 0.5;
    position: relative;

.calendar
  background-color: $c--white;
  position: absolute;
  flex-direction: column;
  z-index: $z-index__input-dropdown;
  $shadow__extra();
  max-width: "calc(%s + %s)" % ($day-size * 7 $day-padding * 2);
  noselect();

  &--month-bar
    justify-content: space-between;
    align-items: center;
    background-color: colorPalette('grey', 1);
    color: $c--white;

  &--month-indicator
    font-family: $font-headings;

  &--days
    flex-flow: row wrap;
    padding: 0 $day-padding $day-padding $day-padding;

  &--day
    position: relative;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: $day-size;
    height: $day-size;
    transition: color $anim-duration;
    cursor: pointer;

    &__header // Mon, tue, etc.
      font-family: $font-headings;
      font-size: 0.8rem;
      padding: 0.5 * $vgu 0;
      background-color: colorPalette('grey', 1);
      color: $c--white;
      margin-bottom: $day-padding;

      &-first, &-last
        width: "calc(%s + %s)" % ($day-padding $day-size);

      &-first
        margin-left: -1 * $day-padding;

      &-last
        margin-right: -1 * $day-padding;


    span
      position: relative;
      top: -1px;

    &__selected
      color: $c--white;

    &__selection-indicator
      position: absolute;
      left: 0;
      background-color: colorPalette('accent', 2);
      border-radius: 50%;
      z-index: -1