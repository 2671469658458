@require '_colours'
@require '_constants'

.fieldset
  margin-left: $vgu;
  display: flex;
  flex-direction: column;
  .input-label
    margin-left: 0;

pxn-day-selector
  .days
    button
      padding: 0;
      margin-right: $vgu;
    .text
      width: 3 * $vgu;
      height: 3 * $vgu;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color $anim-duration, color $anim-duration
      border-radius: 2 * $vgu;
    .selected
      .text
        background-color: $c--accent-2 !important;
        color: $c--white;

pxn-timezone-select, pxn-timezone-select select
  width: 100%;

pxn-timezone-select[disabled], pxn-timezone-select.disabled
  select
    background-color: colorPalette('disabled', 1);
    color: colorPalette('disabled', 2);
    pointer-events: none;
    user-select: none;

.display-periods-button
  margin-left: $vgu;
  display: flex;
  align-items: flex-end;

pxn-labelled-input
  display: flex;
  // flex: 1;
  align-items: flex-start;

$input-border-size = 2px

inputborder()
  border: $input-border-size solid $c--bg-input;
  box-sizing: border-box;
  transition: transform $anim-duration, border-color $anim-duration, background-color $anim-duration;

  &:focus, &:active:not(:disabled)
    outline: 0;
    border-color: $c--border-focus;

  &.ng-invalid.ng-touched, &.ng-invalid.invalid-when-pristine
    border-color: $c--border-negative;


// Text
input, textarea
  padding: 0;

textarea
  resize: vertical;

  //: Fix the yellow autofill background in chrome.;
  &:-webkit-autofill
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    &:disabled
      -webkit-box-shadow: 0 0 0px 1000px colorPalette('disabled', 1) inset;

.input-label
  position: relative;
  margin-bottom: 0;
  margin-left: $input-border-size;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: $c--txt-main;
  font-weight: bold;
  &--white-text
    color: $c--offwhite;
    font-weight: inherit;

  &.input-label__centered
    justify-content: center;

.input
  &__registration-code
    inputborder()
    background-color: $c--bg-input;
    font-size: 4rem;
    height: 8 * $vgu;
    line-height: 6 * $vgu;
    //: padding 2 * $vgu;
    font-family: roboto;
    letter-spacing: $colGutter * 2;
    width: 18rem;
    max-width: 100%;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: $vgu;


[pxn-edit-in-place], pxn-tag-input
  display: inline-block;
  min-width: 2 * $vgu;
  min-height: 2 * $vgu;

input[type="password"]
  font-family: $font-headings;

.labelled-input
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: $vgu;

.labelled-input
  // Clearing X won't be appended in inputfields by Microsoft Edge.
  ::-ms-clear
    display: none;

  &--input, &--textarea, /.color-picker-input-wrapper input, /pxn-period-input input
    position: relative;
    order: 1;
    height: 3 * $vgu;
    z-index: 2;
    background-color: $c--bg-input;
    inputborder()

    &:focus, &.filled, &.ng-invalid
      //

    &.ng-invalid:not(.ng-pristine), &.ng-ivalid.invalid-when-pristine
      border-color: $c--border-negative;

    &:disabled
      background-color: colorPalette('disabled', 1);
      color: colorPalette('disabled', 2);
      border: none;

  &--input
    padding: 0 (2 * $vgu) 0 $vgu;
    &[type="number"]
      padding-right: 0;
      +browser(firefox)
        width: 100%;

  &--textarea
    padding: $vgu;
    height: 10 * $vgu;

  &--label
    order: -1;
    flex: 1;
    margin-bottom: 0;
    font-family: $font-headings;
    padding-left: $input-border-size;
    &__empty
      flex-basis: auto;
      height: 0;
      +browser(safari)
        height: unset;
      & ^[-2]--label-content
        top: -2 * $vgu;
      .labelled-input--label-content
        height: 0;


  &--label-content
    display: flex;
    z-index: 3;
    transition: transform $anim-duration;
    position: relative;
    height: 2 * $vgu;
    white-space: nowrap;

    &:before, &:after
      display: inline-block;
      content: attr(data-content);
      position: absolute;
      left: 0;
      transition: transform 0.2s, opacity 0.2s;
      pointer-events: none;
    &:before
      content: attr(data-ph);
    &:after
      font-weight: normal;
      content: attr(data-label);
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;

    // Internal Placeholder (When not focussed and empty)
    &:before
      transform: rotateX(0) translateY(0);
      opacity: 1;
      top: 2.5 * $vgu;
      left: $vgu;
      transform-origin: 0 -50%;
      color: $c--txt-input-ph;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      font-size: 0.8rem;
      +when(md)
        font-size: 0.9rem;
      +when(xl)
        font-size: 1rem;

    // Label (when focussed or filled.)
    &:after
      top: 0;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: $c--txt-input-label;
  &--clear-button
    height: 3 * $vgu;
    position: absolute;
    right: 0; bottom: 0;
    z-index: $z-index__tooltip;
    &:before
      color: alpha($c--black, 0.7);
      font-size: 1.5 * $vgu;
      line-height: 3 * $vgu;
      position: relative;
      right: 0;
      margin-right: 0.75 * $vgu;
    &__textarea
      right: $vgu;


  &--input:focus + &--label,
  &--input.ng-not-empty + &--label,
  &--input.filled + &--label,
  &--textarea:focus + &--label,
  &--textarea.ng-not-empty + &--label,
  &--textarea.filled + &--label,
  .ng-invalid:not(.ng-pristine) + &--label, .ng-invalid.invalid-when-pristine + &--label
    .labelled-input--label-content
      &:before
        transform: rotateX(90deg) translateY( 2 * $vgu );
        opacity: 0.5;
  // These have to be split out as firefox doesn't understand -webkit-autofill
  // & considers the whole rule block invalid.
  &--input:-webkit-autofill + &--label,
  &--textarea:-webkit-autofill + &--label
    .labelled-input--label-content
      &:before
        transform: rotateX(90deg) translateY( 2 * $vgu );
        opacity: 0.5;

  &--input:focus + &--label
    .labelled-input--label-content:before
      opacity: 0.5;
      transform: rotateX(90deg) translateY( 2 * $vgu );

  &--input:disabled + &--label,
  &--textarea:disabled + &--label
    .labelled-input--label-content:before
      color: colorPalette('disabled', 2);

.json-editor
  font-family: monospace
  padding: $vgu
  margin-bottom: $vgu
  resize: none

pxn-content-field
  & color-picker
    & .color-picker-wrapper
      margin-bottom: 10px;
      & .input-group .input-group-addon:first-child
        border: 0 ;
    & .input-group
    & input
      width: 100%

//: Checkboxes;
// label.checkbox
//   input (hidden)
//   .checkmark
$checkbox-size        = 3 * $vgu
$checkbox-size__small = 2 * $vgu
$checkbox-border-px   = 2px
$switch-size          = 2 * $vgu
$radio-size           = 2 * $vgu
// utility selector
FOCUSSED            = ".checkbox--input:focus"
CHECKED             = ".checkbox--input:checked"
DISABLED            = ".checkbox--input:disabled"
DISABLEDCHECKED     = ".checkbox--input:disabled:checked"
ACTIVE              = ".checkbox--input:active:not(:disabled)"

.checkbox, .checkbox__switch
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $vgu;
  user-select: none;
  cursor: pointer;
  margin-top: $vgu;

  &--input
    //: This input is completely hidden but we still need to focus it;
    //: so we can't use display none.;
    position: absolute;
    width: 0;
    height: 0;
    +browser(safari)
      height: unset;
    border: 0; padding: 0;
    appearance: none;

  &--switch
    position: relative;
    width: $switch-size * 2;
    height: $switch-size;
    border: $checkbox-border-px solid $c--bg-input;
    transition: border-color $anim-duration;
    flex-shrink: 0;
    margin-right: 0.5 * $vgu;

    [modifiers="reverse"] &
      order: 1;
      margin-right: 0;
      margin-left: 0.5 * $vgu;

    [modifiers="dark"] &
      &:after
        background-color: $c--dark-input;

    &:before, &:after
      position: absolute;
      content: "";
      height: "calc(%s - %s)" % ($switch-size ($checkbox-border-px * 2));
      width: "calc(%s - %s)" % ($switch-size ($checkbox-border-px));
      background-color: $c--bg-input;
      transition: background-color $anim-duration;

    //: revealed when checked;
    &:before
      left: 0;

    //: Slides out of view when checked.;
    &:after
      right: 0;

    .checkbox--toggle
      position: absolute;
      left: 0;
      transition: background-color $anim-duration;

      &:before
        content: "";
        background-color: $c--white;
        height: "calc(%s - %s)" % ($switch-size ($checkbox-border-px * 2));
        width: "calc(%s - %s)" % ($switch-size ($checkbox-border-px));
        z-index: 2;
        transition: transform $anim-duration, background-color $anim-duration;

  \ {FOCUSSED} + .checkbox--switch
    border-color: $c--border-focus;

  \ {DISABLED} + .checkbox--switch
    &:after
      background-color: colorPalette('disabled', 1);
    .checkbox--toggle
      &:before
        background-color: colorPalette('disabled', 2);

  \ {CHECKED} + .checkbox--switch
    &:before
      fullComplimentaryColorPalette('positive', 1)
    .checkbox--toggle:before
      transform: "translateX(calc(%s - %s))" % ($switch-size $checkbox-border-px);

  \ {DISABLEDCHECKED} + .checkbox--switch
    &:before
      fullComplimentaryColorPalette('positive', 2)
    .checkbox--toggle:before
      transform: "translateX(calc(%s - %s))" % ($switch-size $checkbox-border-px);

  &--box
    flex: 0 0 $checkbox-size;
    position: relative;
    width: $checkbox-size;
    height: $checkbox-size;
    border: $checkbox-border-px solid $c--bg-input;
    transition: border-color $anim-duration;

    &__small
      width: $checkbox-size__small;
      // Not sure why this is needed but width alone doesn't work
      max-width: $checkbox-size__small;
      height: $checkbox-size__small;
    &__radio
      flex: 0 0 $radio-size;
      width: $radio-size;
      height: $radio-size;


    &:active
      border-color: $c--border-focus;

    \ {FOCUSSED} + &
      border-color: $c--border-focus;

    //: margin-top -1 * $checkbox-border-px;
    &:before, &:after
      content: '';
      display: flex;
      position: absolute;
      transition: background-color $anim-duration;

    //: The box;
    &:before
      width: 100%;
      height: 100%;
      background-color: $c--bg-input;

    \ {DISABLED} + &
      &:before
        background-color: colorPalette('disabled', 1);

    &__radio
      border-radius: 50%;
      &:before
        border-radius: 50%;

    &:after
      left: 0;

    //: The checkmark;
    \[class*='__icon-'] .checkbox--input + &
      &:after
        display: inline;
        width: "calc(%s - %s)" % ($checkbox-size $checkbox-border-px * 2);
        line-height: "calc(%s - %s)" % ($checkbox-size $checkbox-border-px * 2);
        font-size: 1.5rem;
        font-family: 'entypo';
        text-align: center;
        color: $c--txt-input-label;
        transform: scale(0);
        transition: transform 0.2s;

      &__small:after, &__small:before
        font-size: 1.3rem;
        width: "calc(%s - %s)" % ($checkbox-size__small $checkbox-border-px * 2);
        line-height: "calc(%s - %s)" % ($checkbox-size__small $checkbox-border-px * 2);

      / .checkbox--radio&
        //: This is a radio button checkmark;
        &:after
          width: "calc(%s - %s)" % ($radio-size $checkbox-border-px * 2);
          line-height: "calc(%s - %s)" % ($radio-size $checkbox-border-px * 2);
          font-size: 1.5 * $vgu;

    \[class*='__icon-'] {CHECKED} + &,
    \[class*='__icon-'] {ACTIVE} + &
      &:after
        transform: scale(1);

      &__radio
        &:after
          transform: scale(1);

    for $className, $characterCode in $checkIcons
      \.__icon-{$className} .checkbox--input + &
        &:after
          content: $characterCode;

  &--label
    margin-left: $colGutter;
    font-family: $font-headings;
    font-weight: normal;
    font-size: 0.9rem;
    color: $c--txt-input-label;

    // Flex the label with the flex-label class.
    \.flex-label &
      flex: 1;

    &:empty
      display: none;

  &__small
    height: 0 * $vgu;
    transform: scale(0.7);
    transform-origin: left;
    & > pxn-checkbox label.__icon-check
      margin: 0 * $vgu;
    & + .hide-before::before
      display: none;

// Select box

select
  height: 3*$vgu;
  margin-bottom: $vgu;
  padding-left: $colGutter;
  line-height: 2*$vgu;
  background-color: $c--bg-input;
  transition: border-color $anim-duration, background-color $anim-duration;
  inputborder()
  max-width: 100%;
  flex-direction: row !important; // If selects get flex-col, their contents look wrong.

  &:disabled, &.disabled
    background-color: colorPalette('disabled', 1);
    color: colorPalette('disabled', 2);
    pointer-events: none;
    user-select: none;

  &.warning
    border-color: colorPalette('warning', 1)

pxn-content-field
  position: relative;

  select
    width: 100%;

// Volume control
.volume-control



  &--mute-button
    .__icon-mute
      .checkbox--box:after
        transform: scale(1);
    .checkbox--box
      width: 3 * $vgu;
      box-sizing: border-box;
      &:after
        font-size: 0.9rem !important;

  &--container
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .checkbox
      margin: 0;

  &--vol-stop
    flex: 1;
    height: 3 * $vgu;
    background-color: #eee;
    border-right: 1px solid #ddd;

    &:last-of-type
      border-right: 0;

    &.muted
      background-color: colorPalette('disabled', 1);

    &.enabled
      background-color: colorPalette('accent', 2);

    / .disabled &
      background-color: disabledColour(colorPalette('accent', 2));

    &.preview-above
      background-color: pastelise(colorPalette('accent', 2));


.autocomplete

  &--container
    display: flex;
    flex-direction: column;
    background-color: $c--bg-input;
    z-index: $z-index__tooltip;
    border: $input-border-size solid $c--border-focus;
    border-top-width: 0;

  &--suggestion
    flex: 1;
    padding: 0.5 * $vgu $vgu;
    transition: background-color $anim-duration, color $anim-duration;
    background-color: $c--bg-input;
    color: $c--black;

    &__selected
      background-color: colorPalette('neutral', 1);
      color: $c--white;

pxn-resource-grouper
  display: flex;

// Progress bar
// [pxn-progress]
//   flex: 1;
.pxn-progress
  height: 3 * $vgu;
  // margin-bottom: $vgu;
  flex: 1;
  background-color: $c--bg-input;
  .progress
    transition: width $anim-duration;
    background-color: colorPalette('accent', 1);

  &.small
    height: 1.5 * $vgu;

  &.warning
    .progress
      background-color: colorPalette('negative', 1);

recaptcha
  div
    justify-content: center;

.drag-area
  margin: 2 * $vgu;
  padding: 2 * $vgu;
  border: 0.5 * $vgu dashed $c--border-focus;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__large
    min-width: 30 * $vgu;
    min-height: 30 * $vgu;

  &--text
    margin-bottom: 0;

  &--upload-button
    position: absolute;
    bottom: $vgu;
    right: $vgu;
    cursor: pointer;

.multilist
  &-checkbox
    align-items: center;
  &-label
    flex: 1;
    align-items: center;

// Select/dropdown style
.select
  width: 100%
  border: 1px 0 solid grey;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  .select-selected__container
    min-height: 3 * $vgu;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    background-color: #eee;
    margin-bottom: $vgu;
    .select-selected__label
      display: flex;
      align-items: center;
      justify-content: center;
    .select-selected__icon
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    &--expanded
      border: 2px solid black;

    &--disabled
      background-color: #aaa;
      .select-selected__label
      .select-selected__icon
        color: #ccc;


  .select-options
    max-height: 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 2.2rem;
    padding:  0 2px;
    background-color: white;
    border-color: #eee;
    border-style: solid;
    border-width: 1px;
    overflow-y: scroll;
    z-index: $z-index__drag-action-zone;
    .select-option__container
      min-height: 2rem;
      &:hover
        background-color: lightblue;
      .select-option__icon
        color: green;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #555;
        position: relative;
        top: -2px;
      .select-option__label
        display: flex;
        align-items: center;
        color: #555;

// Colour picker tweaks.
.color-picker-wrapper .input-group .input-group-addon
  background-color: black; // default only for colour picker area.
  border: 0.15em solid $c--border-focus !important;

pxn-table-input
  display: flex;
  flex-direction: column;

  .checkbox__switch
    margin: 0.3em 0;
  .checkbox--switch
    margin-right: 0;
  .dndPlaceholder
    background-color: $c--black;
    height: 2.142857142rem;
    list-style: none;
    margin-bottom: 0.5em
  .dndDraggingSource
    display: none !important;
  .dndDragging
    margin-bottom: 0;
  .entypo-menu
    align-items: center;
    cursor: move;
    color: $c--txt-sidebar;
  .fake-button
    width: 2.142857142rem;

  .table
    width: 100%;
    &--headings
      display: flex
    &--heading
      padding-right: 0.5em;
      word-wrap: break-word;
      word-break: break-all;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: #555;
      font-weight: bold;
      align-self: flex-end;
    &--item
      display: flex;
      margin-bottom: 0.5em
    &--column
      display: flex;
      flex: 0 1 auto;
      padding-right: 0.5em;
      &:last-of-type
        padding-right: 0;
      &__full-width
        flex: 1 0 0;
        position: relative;
        align-items: center;
      &__heading
        padding-right: 0;
    &--input
      width: 100%;
      &__title
        padding-left: 3.3em;
        &--decorator
          margin-top: 0.4em;
      &__description
        padding-left: 6.3em;
        &--decorator
          margin-top: 0.1em;
      &__decorator
        position: absolute;
        z-index: 2;
        left: 0.8em;
        margin-bottom: 0;
        color: $c--txt-sidebar;
      &__textarea
        width: 100%;
        height: 6.5 * $vgu;
    pxn-time-input
      flex: 1 0 auto;
      .time-input--input-field
        flex: 1 0 auto; // 4em is default width + table padding
        padding: 0 1em;

    &--media-input
      width: 100%;
    &--buttons
      display: flex;
      flex-wrap: wrap;
    &--button
      display: flex;
      flex: 1 0 0;
      min-width: 45%;
      &:nth-child(odd)
        margin-right: $vgu;
      &:only-of-type
        margin-right: 0;
    &--row-button
      margin-bottom: 0;
      align-self: center;

.ticker-content--input
  flex: 1 1 auto;
  order: 0;
  user-select: none;

pxn-resource-selector
  margin-bottom: $vgu;
  position: relative;
  &[disabled]
    color: colorPalette('disabled', 2);
    .select
      &--input
        background-color: colorPalette('disabled', 1);
  .select
    &--input
      background-color: #EEE;
      min-height: 3 * $vgu;
      max-height: 3 * $vgu;
      height: 3 * $vgu;
      border: 0.2 * $vgu solid #555;
      padding: 0.15em 2.15em 0.15em 0.65em;
      align-items: flex-start;
      overflow: hidden;
      line-height: 1.8em
      position: relative;
      transition: border-color $anim-duration;
      &__closed
        border: none;
      &:after
        position: absolute;
        content: "";
        top: 0.8em;
        right: 0.8em;
        width: 0;
        height: 0;
        border: 0.3em solid transparent;
        border-color: $c--black transparent transparent transparent;
        +browser(safari)
          height: unset;
      &.select-arrow-active:after
        border-color: transparent transparent blue transparent;
        top: 7px;
.select--menu
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.2 * $vgu solid #555;
  background-color: #EEE
  position: absolute;
  z-index: $z-index__modal-content;
  &__hidden
    display: none;
  .menu
    &--tabs, &--tab, &--lists, &--list
      display: flex;
    &--tabs, &--tab, &--list, &--item
      flex: 0 0 auto;
    &--tab, &--lists
      flex: 1 1 auto;
    &--tab
      background-color: #BBB; // unselected
      color: $c--black;
      &:hover
        background-color: #CCC; // unselected & hovered
        cursor: pointer;
      &__active
        background-color: #666; // Selected
        color: $c--white;
        font-weight: 700;
        z-index: 2;
        &:hover
          background-color: #555; // Selected & hovered
          cursor: default;
          &:before
            border-top-color: #555; // TIP selected & hovered
        &:before
          position: relative;
          left: 50%;
          bottom: -130%;
          margin-left: -1 * $vgu;
          width: 0;
          height: 0;
          border: solid transparent;
          border-width: $vgu;
          border-top-color: #666; // TIP - Selected
          content: ' ';
          pointer-events: none;
          +browser(safari)
            height: unset;
    &--lists, &--list
      flex-direction: column;
    &--lists
      overflow-y: scroll;
      margin-top: 5px; // Below shadow.
    &--list
      display: none;
      flex: 0 0 auto;
      overflow: hidden;
      padding-top: $vgu;
      &__active
        display: flex;
    &--controls
      flex: 0 0 auto;
      justify-content: center;
      padding: (1.5 * $vgu) $vgu $vgu;
      box-shadow: 1px 1px 5px 0px #333;
      font-weight: 700;
      .controls
        flex: 0 0 auto;
        &--text-button
          cursor: pointer;
          font-weight: 400;
          user-select: none;
    &--item
      display: flex;
      flex: 0 0 auto;
      position: relative;
      transition: padding 0.1s;
      word-break: break-word;
      &__active
        &.menu--item // We want this over the padding on .menu--item
          padding-left: 2em;
        &:before
          position: absolute;
          content: "\2713 ";
          bottom: 1.7em;
          left: 0.8em;
          width: 0;
          height: 0;
          +browser(safari)
            height: unset;
      &:hover, &__active
        background-color: #AAA;
      &__active:hover
        background-color: #DDD;
      &__empty
        font-weight: 700;
        justify-content: center;
    &--tab, &--item
      min-height: 3 * $vgu;
      padding: 0.5em 0.5em;
      align-items: center;
      user-select: none;
.file-input
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1 * $vgu;

  &--drop-area
    flex: 1;
    border: 0.25 * $vgu dashed $c--dark-input;
    height: 10 * $vgu;
    margin-bottom: 1 * $vgu;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c--dark-input;
    transition: background-color $anim-duration ease-in-out, color $anim-duration ease-in-out, border-color $anim-duration ease-in-out;

    &__dragover
      background-color: colorPalette('positive', 1);
      color: white;
      border-color: white;

  &--select-button
    margin-bottom: 0;
    align-self: flex-end;
  input
    display: none;

.time-input
  &--separator
    color: $c--txt-input-ph;
    line-height: 1.7em;
    ^[1]&__units // .time-input--separator.time-input--separator__units
      line-height: 1.9em;

// API key style inputs like the one in the webhook model. Always disabled.
.api-key
  inputborder()
