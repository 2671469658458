@require '_utility'
@require '_colours'
@require '_constants'

.tooltip--container
  position: fixed !important;
  z-index: $z-index__tooltip;
  border: 0;
  padding: 0;
  //: margin-left -50%;
  &__disabled-reason
    // $z-index__tooltip + 1, so disabled reason tooltip will be rendered on top
    // of other elements, e.g. pxnInvalidityCallouts
    z-index: $z-index__tooltip + 1;

.tooltip--content
  display: block;
  //: margin-left -50%;
  //: margin-right 50%;
  background-color: $c--bg-tooltip;
  color: $c--txt-tooltip;
  padding: 0.5 * $vgu $vgu;
  line-height: 2 * $vgu;
  border-radius: $vgu;
  position: relative;
  max-width: 400px;
  font-size: $font-size-base;
  font-family: $font-main;
  text-align: left;
  font-weight: normal;
  user-select: none;

  &:before
    position: absolute;
    top: -1 * $vgu;
    left: "calc(%s - %s)" % (50% $vgu);
    display: block;
    content: '';
    width: 0;
    height: 0;
    +browser(safari)
      height: unset;

  &.arrow-top
    &:before
      border-style: solid;
      border-color: transparent transparent $c--bg-tooltip transparent;
      border-width: 0 $vgu 1 * $vgu $vgu;


  //: Styling markdown elements.;
  em
    font-style: italic;

  strong
    font-weight: bold;

  code
    background-color: white;
    padding: 2px 5px;
    color: black;
    font-family: monospace;

  a
    color: $c--offwhite;
    text-decoration: underline;

  ul
    margin-left: $colGutter;
    li
      margin-left: 2 * $colGutter;
      &:before
        font-family: 'entypo';
        display: inline-block;
        content: '\e78b';
        margin-right: $colGutter;
        margin-left: -2 * $colGutter;

  p:last-of-type
    margin-bottom: 0;

[pxn-edit-in-place]
  position: relative;
  display: inline-block;
  align-self: flex-start;
  transition: background-color $anim-duration;

[contenteditable=true]
  background-color: $c--bg-input;
  color: $c--txt-input-label;
  min-height: $line-height-body;
  min-width: $colGutter;
  display: inline-block;
  ::selection
    background: colorPalete('accent', 1) !important;



.edit-in-place--hover-icon
  display: inline-block;
  margin-left: $colGutter;
  padding: 0 $colGutter;
  z-index: $z-index__tooltip;
  cursor: pointer;
  &:before
    position: relative;
    display: block;
    font-family: 'entypo';
    content: '\270e';
    background-color: rgba($c--white, 0.4);
    padding: 0 3px 0 3px;
    border-radius: 5px;

.pxn-invalidity-callout
  position: absolute;
  padding: 0.5 * $vgu $vgu;
  // border-radius: 0.5 * $vgu;
  background-color: $c--bg-sidebar;
  min-width: 12rem;
  max-width: 30rem;
  color: $c--txt-tooltip;
  margin-left: $vgu;
  transition: opacity $anim-duration ease;
  z-index: $z-index__tooltip;
  &__modal
    z-index: $z-index__modal-content-overlay;

  // &.ng-enter
  //   opacity: 0;
  //   transition: opacity $anim-duration;
  //   &-active
  //     opacity: 1;
  //
  // &.ng-leave
  //   opacity: 1;
  //   transition: opacity $anim-duration;
  //   &-active
  //     opacity: 0;

  &::after
    content: "";
    display: block;
    width: 0;
    position: absolute;
    top: -1 * $vgu;;
    bottom: auto;
    left: 0 * $vgu;
    border-style: solid;
    border-color: transparent $c--bg-sidebar;
    border-width: $vgu 0 0 $vgu;
  &:hover
    opacity: 0.2;
  span
    user-select: none;