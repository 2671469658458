.groups-page-container
  .saved-groups
    flex: 1 1 0;
  .manage-group
    flex: 0 1 auto;
    +when(md)
      flex: 1 0 auto;
    &--card
      flex: 0 1 auto;
      min-height: 0;
    &--form
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      overflow: hidden;
      +browser(edge)
        min-height: 0;
      .form
        &--heading, &--button-container
          flex: 0 0 auto;
          padding: 0 0.5 * $vgu;
        &--scroll-container.scrollbar__active
          margin-right: 0.5 * $vgu;
        &--accounts
          width: 100%;
          flex: 0 1 auto
          +browser(firefox)
            min-height: 0;
            flex: 1 1 auto
          +browser(edge)
            flex: 0 1 auto !important; // edge does wierd specificity things.
        &--field
          +browser(edge)
            flex: 0 0 100%;
  .group-page-container
    flex-direction: column;
    flex-wrap: nowrap;
    +when(sm)
      flex-direction: row;
      flex-wrap: wrap;
  .about
    &--card
      flex: 0 1 auto;
    &--col
      flex: 0 1 auto;
      +when(md)
        flex: 1 0 auto;
  .group-menu
    &--button
      flex: 0 0 auto;
  .no-saved-groups
    flex-direction: column;
    align-items: center;

list-selector.groups-page
  margin-top: $vgu;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  +browser(firefox)
    min-height: 0;
  +browser(edge)
    flex: 0 1 auto;
    min-height: 0;
  .group-container
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    &--headings, &--group
      flex: 0 0 auto;
