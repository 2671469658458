@require '_constants';
@require '_colours';

.modal // This is the modal container.
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background-color: $c--bg-modal-overlay;
  z-index: $z-index__modal-bg;
  overflow: hidden;

  // : Make sure we are visible when transitioning in and out.;
  &__visible, &.ng-animate
    visibility: visible;

  &--content
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    background-color: $c--bg-content;
    padding: $modalPadding;
    z-index: $z-index__modal-content;

    +when(sm)
      position: static;
      width: auto;
      min-width: 10rem;
      max-width: $modalMaxWidth;

  &--container
    position: relative;
    max-height: 100%;
    display: none;

    &:first-child
      display: flex;

  &--close-button
    position: absolute;
    top: $colGutter;
    right: 0;

    button:before
      font-size: 2em;

  &--toast-container
    position: fixed;
    top: 100vh;
    width: 100vw;
    left: 0;
    z-index: $z-index__modal-content + 1;

    +when(sm)
      position: absolute;
      width: auto;
      left: 0;
      top: 0;
      right: 0;
      z-index: auto;

  &--toaster
    flex: 1;

  &--buttons
    margin-top: 1 * $vgu;
    // : Cancel out the margin on the bottom of the buttons (because of modal padding);
    margin-bottom: -1 * $vgu;
    // : These never want to shrink.;
    flex-shrink: 0;

  &__createFeed
    .connected-account-required
      display: block;
      color: $c--shades.negative;
      margin-bottom: $vgu;

  &__confirmation, &__deleteUserAccount
    .warning
      color: $c--shades.negative;

  &__confirmation, &__passwordReset, &__savecheck, &__areYouSure, &__addSlide, &__register-screen,
  &__modalPendingChanges, &__genericMessage, &__massAssignTemplates, &__manageEmailAlertList,
  &__connectToAnyChannel, &__addSlideToChannel, &__updateChannelsFromSlidesPage, &__massactionconfirm
  &__slideExpired
    // : background-color red;
    +when(sm)
      max-width: 32rem;

  &__userSettings
    max-width: 30rem;

  &__manageEmailAlertList
    width: 50em;
    .entry
      &--input
        display: flex;
        flex: 1
        margin-right: $vgu;
      &--button
        flex: 0 0 auto;
        order: 1;
    .no-entries-message
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 1em;
      color: colorPalette('disabled', 1);

  &__connectToAnyChannel
    .content-select
      max-width: "calc(100% - 4 * %s)" % ($vgu);

  &__editMedia, &__editPlaylist, &__editChannel, &__azureSettings
    +when(sm)
      width: 32rem;

  &__manageProviders
    .odd
      background-color: #ddd;

  &__register-screen, &__replace-screen
    .regcode-container
      display: flex;
      align-self: center;
      flex-direction: column;
      width: 16rem;

    pxn-tag-input[editable='true']
      margin: 0.5 * $vgu 0 1.5 * $vgu;
      // : margin-top 0.5 * $vgu;

    recaptcha
      margin-top: $vgu;

  &__replace-screen
    .regcode-container
      .justify-center-xs
        justify-content: center !important;

  &__select-media
    width: $modalMaxWidth;
    max-width: 100%;

  &__enlargedView
    max-height: 80vh;

    +when(sm)
      max-width: 50 * $vgu;

    +when(md)
      max-width: 70 * $vgu;

    +when(lg)
      max-width: 100 * $vgu;

  &__view-screenshot
    .screenshot
      &--image
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 100%;
        max-height: 100%;

        &__landscape
          height: 36 * $vgu;
          width: 64 * $vgu;

        &__portrait
          height: 80 * $vgu;
          width: 45 * $vgu;

      &--date
        text-align: end;
        font-size: 1em;
        margin-top: 0.714285714rem;

    .modal-buttons
      width: 100%;

  &__addSlideToChannel
    .warning
      text-transform: uppercase;
      font-weight: bold;
      color: $c--shades.negative;

  &__terminal
    // Default to full width/ height for mobile.
    padding: 0;
    width: 100vw;
    height: 100vh;
    &.ps
      overflow: visible !important;

    &.terminalModal__fullscreen
      // This is needed to override `.modal--content` maxWidth
      max-width: 100vw;

    // Make smaller by default for others unless fullscreen
    +when(sm)
      &:not(.terminalModal__fullscreen)
        max-width: 80vw;
        max-height: 90vh;

    +when(xl)
      &:not(.terminalModal__fullscreen)
        max-width: 140 * $vgu;
        width: 140 * $vgu;

    // Buttons
    .terminalModal--button-container
      position: absolute;
      top: 0;
      right: 2 * $vgu;
      display: flex row;
      justify-content: flex-end;
      z-index: $z-index__modal-content-overlay;

    .terminalModal--button
      transition: color $anim-duration ease-in-out;
      color: #ddd;

      &:hover
        color: white;

      &__toggle
        color: #777;

        &:hover
          // Terminal red
          color: #951622;

  .enlarged-view
    &--image, &--video
      max-height: 70vh;

  &__takeoverWebhook
    +when(sm)
      max-width: 40 * $vgu;

  .screen
    background-color: black;
    width: 4.977777778em;
    height: 2.8em;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: $c--txt-tooltip;
    font-size: 0.9em;
    font-weight: bold;

    +when(sm)
      font-size: 1em;
      font-weight: normal;

    p
      margin: 0;
      text-transform: uppercase;

    &__east
      transform: rotate(90deg);

    &__south
      transform: rotate(180deg);

    &__west
      transform: rotate(270deg);

    &__portrait
      .checkbox--label
        margin-left: 0;

  &__deviceComments
    +when(sm)
      margin: 4em 0;
      min-width: 40em;

    .row
      .scroll-element
        position: relative;
        padding-right: 1em;

    +browser(edge)
      .modal--main
        flex-direction: column;

        comment-list
          flex: 0 1 auto;
          min-height: 0;
          +browser(safari)
            min-height: 0%;
          ul
            +when(md)
              flex: 1 1 auto;

    +browser(firefox)
      .modal--main
        flex: 1 1 0;

        +when(md)
          min-height: 50vh;

        comment-list
          ul
            flex: 1 1 0;

  &__areYouSure-channels
    .list
      list-style-type: disc;
      margin-left: 3 * $vgu;
      margin-bottom: 2 * $vgu;

      &--item
        .highlight-repeats
          color: red;
          font-weight: bold;

  &__preview-slide
    min-width: 40em;

  &__localeManager
    height: 90vh;
    pxn-locale-tree[locale-manager]
      width: 45em;
    pxn-locale-tree[locale-selector]
      width: 35em;
    .compansate-scrollbar
      margin-right: -1 * $vgu;
      padding-right: $vgu;
    & [pxn-scroll-element].scrollbar__active.ps--active-y
      padding-right: $vgu;

  &__takeoverTemplate
    min-width: 50em;

  &__enable2fa
    max-width: 35em;

  &__disable2fa
    max-width: 35em;

  &__exportAsImage
    max-width: 28em;

    .loading
      align-self: center;
      margin-bottom: $vgu;
      display: inline-block;
      font-size: 2em;
