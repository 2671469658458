.page-overview-button
  z-index: $z-index__content;
  margin-right: $vgu;
  position: relative;
  align-self: flex-end;
  +when(md)
    margin-right: 0;
    position: fixed;
    top: $vgu;
    right: $vgu;
    max-width: unset;
  .button__icon
    &--icon-container
      min-height: 3 * $vgu;
      icon::before
        content: "\2753";
    &--text
      display: none;
  ng-transclude
    top: 4.3 * $vgu;
    .dropdown-menu--item:hover
      .dropdown-menu--item-label
        background-color: pastelise(colorPalette('neutral', 2));
        border-bottom-color: colorPalette('neutral', 2);
    .dropdown-menu--item-icon
      display: none;
    li
      min-height: 3 * $vgu;

/* Do not highlight elements or display layers */
.introjs-helperLayer.no-highlight,
.introjs-tooltipReferenceLayer.no-highlight {
  display: none;
}
.introjs-showElement.no-highlight {
  z-index: auto !important;
}

.introjs-bullets ul li a.active
  background: $c--pixel-purple;
  margin-bottom: $vgu;

.introjs-tooltip
  display: flex;
  flex-direction: column;
  border-radius: 0;
  padding: 1 * $vgu;
  padding-top: 2 * $vgu;
  min-width: 30 * $vgu;
  max-width: 60 * $vgu;
  .introjs-tooltipbuttons
    display: flex;
  .disable-overviews
    position: relative;
    top: -1 * $vgu;
    right: - 0.5 * $vgu;
    text-decoration: underline;
    font-size: 1 * $vgu;
    margin-top: -1 * $vgu;
  .introjs-tooltiptext
    display: block;
    margin-bottom: $vgu;
  .introjs-bullets ul
    margin: 0.5 * $vgu auto 0.7 * $vgu;
    // margin-bottom: 0.7 * $vgu;

.introjs-button
  flex: 0 0 auto;
  &--snooze
    margin-right: 0.5 * $vgu;
  &:before
    margin: 0;
  &.introjs-hidden
    display: none;
  &:focus, &:focus:not(:hover)
    outline: 0;
    border-color: #7D0079;
  &.introjs-skipbutton
    margin-right: auto;
    color: #333;
  &.introjs-prevbutton
    margin-left: 1.5 * $vgu;

  &.introjs-nextbutton, &.introjs-donebutton
    margin-right: 0;
    margin-left: 0.5 * $vgu;
    order: 100; // Make sure the done button is always on the far right.

  // This selects 'back' button when there's no 'skip' (last step with 'done')
  &.introjs-donebutton + &.introjs-prevbutton
    margin-left: auto
  &.introjs-donebutton + &.introjs-prevbutton
    margin-left: auto

.introjs-hidden
  display: none !important;

.introjs-helperLayer
  border-radius: 0;
  background-color: #fff !important;
