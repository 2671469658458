@require '_colours'
@require '_constants'

.toast

  &--placeholder-heading
    padding-top: 1 * $vgu;
    text-align: center;

  &--toaster
    display: flex;
    flex: 1;
    font-family: $font-headings;
    flex-direction: column;
    background-color: $c--bg-header;
    color: $c--offwhite;

  &--container
    position: fixed;
    align-items: center;
    padding: 0.5 * $vgu;
    z-index: $z-index__toasts;
    font-family: $font-headings;
    left: 0;
    right: 0;
    top: 0;

    .sidebar__open &
      left: $sidebarWidth

    +when($sidebarBreakpoint)
      left: $sidebarWidth

    &__success
      background-color: colorPalette('positive', 1);
      color: white;

    &__info
      background-color: colorPalette('neutral', 1);
      color: white;

    &__error
      background-color: colorPalette('negative', 1);
      color: $c--offwhite;

  &--icon
    margin: 0 4 * $colGutter 0 2 * $colGutter;
    font-size: 2em;
    align-self: center;

  &--message
    align-self: center;
    &__link
      color: $c--offwhite;
      font-weight: 700;
      cursor: pointer;

  &--dismiss
    flex-shrink: 0;
    width: 3 * $vgu;
    margin-right: $colGutter * 1;
    cursor: pointer;
    margin-bottom: 0;

  &__modal, &__login
    transform: translateY(-100%);
    position: static;
    flex: 1;