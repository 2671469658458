@require 'nib'
// Colour functions
pastelise($inputColor)
  if lightness($inputColor) > 60%
    $inputColor - hsl(0,10,0) - #444
  else
    $inputColor - hsl(0,10,0) + #444

compliment($inputColor)
  if lightness($inputColor) > 20%
    $inputColor - hsl(0,10,0) - #111
  else
    $inputColor - hsl(0,10,0) + #111

disabledColour($inputColor)
  $inputColor - hsl(0,40,0) + #222

contrastingText(bgColour, disabled=false)
  if lightness(bgColour) > 50%
    //: color bgColour - hsl(0,75,0) - #666;
    if !disabled
      color: $c--black;
    else
      color: alpha($c--black, 0.5);
  else
    //: color bgColour - hsl(0,75,0) + #999;
    if !disabled
      color: $c--offwhite;
    else
      color: alpha($c--offwhite, 0.5);

// Just colours
$c--white             = #ffffff;
$c--semi-t-white      = alpha($c--white, 0.5);
$c--offwhite          = #eeeeee;
$c--black             = #444444;
$c--pixel-purple      = #7d0079;
$c--dark-input        = #DADADA;

$c--shades = {
  accent: $c--pixel-purple #004C7D #019D96 #6115a6,
  positive: #2E9B5B #91BFA4 lighten(#91BFA4, 80%),
  negative: #C8321F,
  neutral: #879AA8 #75787c #eeeeee,
  grey: #333333 #666666 #999999 #cccccc #eeeeee
  selected: #E1E6EA,
  contrast: #FF9523,
  warning: #FF7500 pastelise(#FF7500),
  disabled: #aaaaaa #cccccc,
  //: SOCIAL;
  beekeeper: #00abc2,
  facebook: #3B5998,
  instagram: #c32aa3,
  pinterest: #bd081c,
  powerbi: #f2c812,
  twitter: #55ACEE,
  rss: #FF6600,
  workplace: #323A47
  yammer: #0072C6,
  //: EASTER EGGS;
  halloween: $c--halloween-orange,
}

$c--accent-1        = $c--pixel-purple;
$c--accent-1__compl = pastelise($c--accent-1);
$c--accent-1__text  = $c--offwhite;
$c--accent-2        = #004C7D;
$c--accent-2__compl = pastelise($c--accent-2);
$c--accent-2__text  = $c--offwhite;

// Easter Egg colours
$c--halloween-orange  = #FF9100;
$c--halloween-orange__compl = pastelise($c--halloween-orange);
$c--halloween-orange__text  = $c--black;
$c--christmas-dark-green = #2c5d3d;
$c--christmas-green = #a8ab47;
$c--christmas-light-green = #c3d19c;
$c--christmas-red = #c83433;
$c--christmas-white = #dad9df;


// Text colours
$c--txt-main               = #555555;
$c--txt-sidebar            = #999999;
$c--txt-sidebar__hover     = #333333;
$c--txt-sidebar__secondary = #333333;
$c--txt-tooltip            = #cccccc;
$c--txt-button             = #999999;
$c--txt-input-ph           = #aaaaaa;
$c--txt-input-label        = #555555;
$c--txt-link               = $c--shades['accent'][1];
$c--txt-link__subtle       = #aaaaaa;

// Borders
$c--border-focus    = #555555;
$c--border-negative = $c--shades['negative'][0];

// Backgrounds
$c--bg-main           = #e3e3e3;
$c--bg-content        = #ffffff;
$c--bg-sidebar        = #333333;
$c--bg-sidebar__dark  = #222222;
$c--bg-header         = #333333;
$c--bg-tooltip        = #333333;
$c--bg-sidebar__hover = #cccccc;
$c--bg-button         = #333333;
$c--bg-button-border  = pastelise($c--bg-button);
$c--bg-input          = #eee;
$c--bg-modal-overlay  = #373A47E5;
$c--bg-preview        = #111;

.blue-radial-gradient
  background-image: radial-gradient(ellipse farthest-corner at center center, white 0%, #004C7D 100%);

// Shadows
$shadow__standard()
  box-shadow: 0 0 10px #3335;

$shadow__extra()
  box-shadow: 0 0 15px 2px #3339;

$shadow__emphasize()
  box-shadow: 0 0 6px 2px #333b;

$shadow__selected()
  box-shadow: 0 0 10px colorPalette('accent', 2);

// Background classes based on the shades above.
for type, colorList in $c--shades
  for $inputColor, i in colorList
    .bg-{type}-{i+1}
      background-color: $inputColor;
      contrastingText($inputColor)
      border-color: pastelise($inputColor);

      &:active:not(:disabled), &__active
        background-color: darken($inputColor, 20%);

      &:disabled, [disabled="disabled"] &, .disabled &,
      / *:disabled .bg-{type}-{i+1}
        background-color: disabledColour($inputColor);
        contrastingText(disabledColour($inputColor), true)
        border-color: disabledColour($inputColor);

    .bg-{type}-{i+1}-inverse
      background-color: compliment($inputColor);
      contrastingText($inputColor)
      border-color: pastelise(compliment($inputColor));

      &:active:not(:disabled), &__active
        background-color: darken(compliment($inputColor), 20%);

      &:disabled, [disabled="disabled"] &, .disabled &,
      / *:disabled .bg-{type}-{i+1}-inverse
        background-color: disabledColour(compliment($inputColor));
        border-color: disabledColour(compliment($inputColor));
        contrastingText(disabledColour(compliment($inputColor)), true)

colorPalette(type, i)
  return $c--shades[type][i - 1]

fullColorPalette(type, i)
  $inputColor = $c--shades[type][i - 1]
  background-color: $inputColor;
  contrastingText($inputColor)
  border-color: pastelise($inputColor);

fullInverseColorPalette(type, i)
  $inputColor = $c--shades[type][i - 1]
  background-color: pastelise($inputColor);
  contrastingText: pastelise($inputColor);
  border-color: ($inputColor);

fullComplimentaryColorPalette(type, i)
  $inputColor = compliment($c--shades[type][i - 1])
  background-color: $inputColor;
  contrastingText($inputColor)
  border-color: pastelise($inputColor);

.coloured-icons
  &.entypo
    &-check
      color: $c--shades['positive'][0]
    &-cancel
      color: $c--shades['negative'][0]