.users-page-wrapper
  .user-list
    +browser(edge)
      flex: 0 1 auto;
      min-height: 0;
    &--card
      flex: 0 1 auto;
      flex-direction: column;
      max-height: "calc(80vh - %s - %s)" % ($contentHeaderHeight 2 * $vgu);
      min-height: 10em;
    &--heading
      flex: 0 0 auto;
    &--list
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;
      margin-right: -1.5 * $vgu;
      padding-right: 2 * $vgu;
      +browser(edge)
        flex: 0 1 auto;
        min-height: 0

.user-list
  width: 100%;
  margin-bottom: $vgu;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  thead
    display: flex;
    flex: 0 0 auto;
  tbody
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    &.scrollbar__active
      margin-right: -1.5 * $vgu;
      padding-right: 1.5 * $vgu;
  thead tr, tbody tr
    display: flex;
  thead tr
    flex: 1 1 auto;
  tbody tr
    flex: 0 0 auto;
  th, td
    display: flex;
    flex: 1 1 0;
  &--header
    text-align: center;
    &-name
      padding-left: 4.5 * $vgu;
    &.user-list--header-2fa
      flex: 0 0 3 * $vgu;

  &--section
    // This is a td element
    padding: $vgu $vgu;
    min-width: 0;
    &.user-list--section__2fa
      flex: 0 0 3 * $vgu;

  &--user
    cursor: pointer;
    transition: background-color $anim-duration;

    &__selected
      background-color: colorPalette('selected', 1);

    &:hover
      background-color: colorPalette('grey', 5);

    &-gravatar
      margin-bottom: 0;

    &-identity
      margin-left: $vgu;

    &-name
      font-family: $font-headings;
      font-size: 0.9rem;

    &-last-seen
      margin-left: $colGutter;
      font-size: 0.8rem;
      position: relative;
      top: -1px;
      font-style: italic;
      color colorPalette('grey', 3)

  &--role
    flex: 1;

    &-selector
      flex: 0 1 auto;
      margin-bottom: 0;

  &--2fa-icon
    margin-top: 0.5 * $vgu;

    &__enabled
      color: $c--shades['positive'][0]

    &__disabled
      color: $c--shades['neutral'][0]

.user-detail
  &--info__placeholder
    margin-bottom: $vgu;
    padding: $vgu;

  &--appointment-list
    &__empty
      margin: 2 * $vgu 0
      color: colorPalette('grey', 3);

    margin-bottom: 2 * $vgu;

  &--appointment
    display: flex;
    &-role, &-account
      flex: 1;
      margin-bottom: 0;
    &-separator
      margin: 0 $vgu;
      line-height: 3 * $vgu;
    &-delete
      margin-left: $vgu;

.azure-managed
  color: #999;
  span
    padding: 0 0.5 * $vgu;


pxn-permissions-leaf, pxn-permissions-children,
pxn-permission-filter-editor
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

.permissions-tree
  margin-bottom: $vgu;
  &--permission-filter-button
    flex-shrink: 0;
    margin-left: $vgu;
    margin-bottom: 0;
    button
      margin-bottom: 0;
    &__selected
      //
    &__active
      // when a particular permission has filters enabled.

  &--leaf
    margin-right: -2 * $colGutter;
    padding-right: 2 * $colGutter;
    transition: background-color $anim-duration;
    &__editing
      background-color: $c--bg-sidebar__hover;

    &-expander
      width: 2 * $vgu;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .accordion-heading--icon
        margin-bottom: 0;
    &-children
      flex: 1;
      margin-left: 2 * $vgu

  &--filter-container
    margin-bottom: 0;

  &--filters
    flex: 1;

.permission-filter-editor
  flex-direction: column;
  flex: 1 0 auto;

  &--tag-and-owner
    flex-shrink: 0;
    .checkbox__switch
      // margin-bottom: 0;

  // This is the tag label.
  &--tags, pxn-tag-input
    flex-shrink: 0;

  &--item-list
    flex-direction: column;
    flex: 1 0 auto;

  &--item-list, &--item
    margin: 0 -2 * $colGutter;
    padding: 0 2 * $colGutter;

  &--item
    flex-shrink: 0;
    transition: background-color $anim-duration;
    // Make a check label full width so we can separate tags from name
    .checkbox--label, pxn-checkbox
      flex: 1;

    &__match
      background-color: colorPalette('selected', 1);

    &-name
      margin-right: $vgu;
