@require 'nib'
@require '_constants'
@require '_colours'

.disable-animations
  //Use: this for transitions;
  &.ng-enter,
  &.ng-leave,
  &.ng-animate
    transition: none !important;

  //Use: this for keyframe animations;
  &.ng-animate
   -webkit-animation: none 0s;
   animation: none 0s;

// Generate .transition__{property} classes for one-off transitions.
.transition
  $properties = opacity transform;

  for $property in $properties
    &__{$property}
      transition: $property $anim-duration;

@keyframes fadeIn
  0%
    opacity: 0;
  100%
    opacity: 1;

@keyframes fadeOut
  0%
    opacity: 1;
  100%
    opacity: 0;

@keyframes flipInX
  0%
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  40%
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  60%
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    animation-timing-function: ease-in;
    opacity: 1;
  80%
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  100%
    transform: perspective(400px);

.refreshIconSpin
  .button__icon--icon
    animation: zAxisSpin 1s infinite linear reverse;
    transform-origin: center;
    display: flex;
    &:before
      position: static;
      margin-right: 0 !important;
      align-self: center;

.iconSpin
  animation: zAxisSpin 0.5s infinite linear
  transform-origin: center;
  &:before
    margin-right: 0 !important;
    align-self: center;

.reverse-animation-direction
  animation-direction: reverse;

.dragImageNoBackgroundColor
  background-color: rgba(0, 0, 0, 0.15);

.moveable
  &:hover
    cursor: -webkit-grab;
    cursor: grab;
  &[drag-divider]
    &[drag-direction="horizontal"]
      cursor: col-resize;
    &[drag-direction="vertical"]
      cursor: row-resize;

.divider
  position: relative;
  background-color: $c--pixel-purple;
  z-index: 1;
  &--horizontal // row
    width: 0.4em;
    margin: 0 -0.2em;
    height: 100%;
    &[always-visible]
      margin: 0;
      z-index: unset;
  &--vertical // column
    height: 0.4em;
    margin: -0.2em 0;
    width: 100%;
    &[always-visible]
      margin: 0;
      z-index: unset;

.hide-until-layout-reordered
  opacity: 0;


@keyframes zAxisSpin
    from
      transform: rotate(-360deg);
    to
      transform: rotate(0deg);

@keyframes subtleZoomIn
  0%
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  100%
    opacity: 1;
    transform: scale3d(1, 1, 1);

@keyframes subtleZoomAway
  0%
    opacity: 1;
    transform: scale3d(1, 1, 1);
  100%
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);

@keyframes fadeInUp
  0%
    opacity: 0;
    transform: translateY(1 * $vgu);
  100%
    opacity: 1;
    transform: translateY(0);

@keyframes fadeOutDown
  0%
    opacity: 1;
    transform: translateY(0);
  100%
    opacity: 0;
    transform: translateY(1 * $vgu);

@keyframes toastIn
  0%
    transform: translateY(0);
  100%
    transform: translateY(-100%);

@keyframes toastOut
  0%
    transform: translateY(-100%);
  100%
    transform: translateY(0);

angularAnimationClasses(enterName, leaveName, tEnter = $anim-duration, tLeave = $anim-duration)
  .animate__{enterName}-{leaveName}
    animation-fill-mode: both;
    &.ng-enter-active, &.ng-leave-active, &.ng-hide-remove-active, &.ng-hide-add-active
      display: flex !important;
    &-add, &.ng-enter, &.ng-hide-remove
      animation: tEnter enterName;
    &-remove, &.ng-leave, &.ng-hide-add
      animation: tLeave leaveName;

// TODO check whether we need any initialisation before animate (e.g. calendar glitch)
angularAnimationClasses(fadeInUp, fadeOutDown)
angularAnimationClasses(flipInX, fadeOut, 0.5s, $anim-duration)
angularAnimationClasses(fadeIn, fadeOut)
angularAnimationClasses(subtleZoomIn, subtleZoomAway, 0.2s)
angularAnimationClasses(toastIn, toastOut, $anim-duration)
