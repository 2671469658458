@font-face
  font-family: 'officeUIfabric';
  src: url('../fonts/fabricmdl2icons.woff2');
  font-style:normal;
  font-weight:400;

[class*="pxn-icon-"]
  &:before
    display: inline-block;
    content: '';
    width: 2 * $vgu;
    height: $vgu * 2;    // $vgu * 1.5 is too small....;
    margin-right: $vgu;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

.pxn-icon
  &-beekeeper
    // This is in the feeds sidebar.
    &:before
      background-image: url('/img/icons/custom/beekeeper.svg');
    &:hover
      opacity: 0.6;
      color: darken($c--txt-sidebar__hover, 200%) !important;
    // This is on the authentication button (needs to be white due to bg color)
    \ integration &
      display: flex;
      &:before
        opacity: 1;
        margin-right: 0;
        filter: invert(1);
  &-yammer
    &:before
      width: 2.5 * $vgu;
      height: 2.5 * $vgu;
      background-image: url('/img/icons/custom/yammer-dark.png');
      background-size: 120%;
      margin-right: .5 * $vgu;
      margin-top: -0.25 * $vgu;
    &.feed-menu-classes
      &:before
        background-size: 140%;
    \ integration &
      &:before
        background-image: url('/img/icons/custom/yammer.png');
  &-microsoft
    &:before
      background-image: url('/img/icons/custom/ms_logo.svg');
    &.gravatar-icon
      position: absolute;
      bottom: -0.3 * $vgu;
      right: -0.5 * $vgu;
      &:before
        margin: 0.1 * $vgu;
  &-markdown:before
    background-image: url('/img/icons/custom/markdown.svg');
  &-qr:before
    background-image: url('/img/icons/custom/qr.svg');
  &-tm:before
    background-image: url('/img/icons/custom/tm.svg');
  &-social:before
    background-image: url('/img/icons/custom/social.svg');
  &-layout:before
    background-image: url('/img/icons/custom/layout.svg');
  &-uhd:before
    background-image: url('/img/icons/custom/4k.svg');
  &-just-giving:before
    background-image: url('/img/icons/custom/justgiving.svg');
  &-national-rail:before
    background-image: url('/img/icons/custom/nationalrail.svg');
  &-british-gas:before
    background-image: url('/img/icons/custom/british-gas.svg');
  &-workplace
    // This is in the feeds sidebar (needs to be off-black with margin right).
    &:before
      filter: invert(1);
      background-image: url('/img/icons/custom/workplace.svg');
    &:hover
      opacity: 0.6;
      color: darken($c--txt-sidebar__hover, 200%) !important;
    // This is on the authentication button (needs to be white due to bg color)
    \ integration &
      display: flex;
      &:before
        opacity: 1;
        margin-right: 0;
        filter: invert(0);
  &-instagram
    // This is in the feeds sidebar (needs to be off-black with margin right).
    &:before
      background-image: url('/img/icons/custom/instagram.svg');
    &:hover
      opacity: 0.6;
      color: darken($c--txt-sidebar__hover, 200%) !important;
    // This is on the authentication button (needs to be white due to bg color)
    \ integration &
      display: flex;
      &:before
        opacity: 1;
        margin-right: 0;
        filter: invert(1);
        color: red
  &-azure-white
    &:before
      background-image: url('/img/icons/custom/azure-white.png');
      margin-right: 0;

// This styles the "connect account" button
.button__small
  & .pxn-icon-yammer:before
    margin-top: .5 * $vgu;
    margin-right: 0;
    background-size: 120%;
    width: 2 * $vgu;
    height: 2 * $vgu;
