@require '_constants'
@require '_colours'

.config-override
  &--container
    margin-bottom: 1 * $vgu
    display: grid
    grid-template-columns: auto 1fr 1fr 1fr
    grid-template-rows: repeat(auto-fill, 1fr)
    max-height: 40 * $vgu

  &--level-row
    display: contents

  &--header

    &__level
      justify-content: center
      font-weight: bold
      text-transform: uppercase
      font-size: 0.8rem
      margin-bottom: 0.5 * $vgu
      position: sticky
      top: 0px
      background-color: white

    &__path
      justify-content: left
      font-family: monospace

  &--path-row
    display: contents

  &--cell
    justify-content: center
    background-color: #eee
    margin-left: 0.5 * $vgu
    margin-bottom: 0.5 * $vgu
    padding: 0

    &__selected
      background-color: $c--shades.positive[1]
      color: $c--black

    &__content
      align-self: center

  &--error__box
    background-color: $c--shades.negative
    color: white
    margin-bottom: 0.5 * $vgu
    padding-left: 0.5 * $vgu

.config-diff
  &--container
    margin-bottom: 1 * $vgu
    display: grid
    grid-template-columns: 1fr 1fr 1fr auto
    grid-template-rows: repeat(auto-fill, 1fr)
    max-height: 40 * $vgu

  &--header
    justify-content: center
    font-weight: bold
    text-transform: uppercase
    font-size: 0.8rem
    margin-bottom: 0.5 * $vgu
    position: sticky
    top: 0px
    background-color: white

  &--cell
    justify-content: center
    border-bottom: grey
    background-color: #eee
    margin-left: 0.5 * $vgu
    margin-bottom: 0.5 * $vgu
    padding: 0

    &__content
      align-self: center

    &__button
      margin-bottom: 0.5 * $vgu
      margin-left: 0.5 * $vgu

  &--simple-div
    display: contents