@require '_constants'

.scroll-page-container
  #newComment
    height: 8 * $vgu;
  .saved-search__selected
    flex: 0 0 auto;

.ct-series-b .ct-slice-donut
  stroke: $c--bg-main;

.screensOnline
  height: 15 * $vgu;
  margin-top: $vgu;
  flex-shrink: 0;

.saved-searches
  flex: 1 1 0;
  padding-right: $vgu;
  margin-right: -1 * $vgu;
  padding-bottom: $vgu;
  .no-saved-searches
    flex-direction: column;
    text-align: center;

.screens-online-text
  color: $c--txt-main;

.device-detailed-modal
  position: relative;
  max-height: 80vh;
  max-width: 100%;
  padding-right: 11px;
  overflow: hidden;

.device-list
  flex: 1 1 auto;
  padding-bottom: 1 * $vgu;
  padding-right: $colGutter;
  padding-left: 0;
  noselect()
  &--wrapper
    +when(md)
      flex: 1 1 0;
      min-height: 0;
      +browser(safari)
        min-height: 0%;

  &--orientation
    display: flex;
    align-items: center;

  &--menu-icon
    width: 1.5 * $vgu;
    color: #ccc;

  &--item
    position: relative;
    min-height: 3 * $vgu;
    line-height: 3 * $vgu;
    display: flex;
    flex: 0 0 auto;
    border-bottom: 1px solid #eee;
    margin-bottom: -1px;
    margin-left: 0;
    transition: background-color $anim-duration, opacity $anim-duration;

    &__selected
      background-color: colorPalette('selected', 1);

    &__unlicensed
      opacity: 0.5;

  &--first-col
    padding-left: 0;
  &--status
    position: relative;
    top: 1px;
    margin-top: -1px;
    align-self: stretch;
    width: 0.5rem;
    flex-shrink: 0;
    margin-right: $vgu;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    &__flex
      flex: 1 0 100%;

    &__online
      fullColorPalette('positive', 1)

    &__offline
      fullColorPalette('negative', 1)

    &__locked
      background-image: linear-gradient(transparent 50%, colorPalette('warning', 1) 50%);
      background-size: 10px 10px;

  &--tags
    justify-content: center;

  &--channel-select
    //: so that it overlaps the border of the column;
    margin-bottom: 0;
    margin-left: - 1 * $colGutter;
    margin-right: - 1 * $colGutter;
    background-color: transparent;
    border-color: transparent;

  &--channel-select-container
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .content-indicator
      flex: 1 1 auto;
      text-align: left;
      padding: 0.5 * $vgu;
      line-height: 1.4em
      font-size: 0.8em;
      &--not-own-content
        color: colorPalette('contrast', 1);
      &--container
        margin-bottom: 0.5 * $vgu;
        &:last-of-type
          margin-bottom: 0;
      &--icon
        flex: 0 0 auto;
        align-items: center;
  &--takeover-comment-container
    span
      .entypo-megaphone, .entypo-comment
        &::before
          margin-right: 0;
      .entypo-comment
        margin-top: 0.75 * $vgu;
        flex-direction: row-reverse;
        font-style: normal;
        cursor: pointer;
        flex: 0 0 auto;
        &:before
          margin-right: 0;
        &:after
          content: attr(after-content);
          position: relative;
          margin-right: 0.25 * $vgu;
      .no-comments:after
        content: "";
.grey-border-r
    box-sizing: border-box;
    border-right: 1px solid #eee;
    line-height: 2 * $vgu;
.grey-border-t
    box-sizing: border-box;
    border-top: 1px solid #eee;
    line-height: 2 * $vgu;
.device-info
  position: relative;
  +when(edge)
    height: 0;
    min-height: 100%;
  &--buttons
    display: grid;
    grid-column-gap: $vgu;
    grid-template-columns: repeat(2, 1fr);
    +when(sm)
      grid-template-columns: repeat(3, 1fr);
    +when(xl)
      grid-template-columns: repeat(4, 1fr);

  &--container
    position: relative;
    margin-top: -1 * $vgu;
    padding-top: $vgu;
    +browser(firefox)
      max-height: 100%;
    +browser(edge)
      max-height: 100%;
  &--timezone
    position: relative;
    top: $vgu * -0.5;
    max-width: 100%;
    margin-bottom: $vgu * -0.5;

.no-checkbox-margin
  label
    margin-top: 0;
.device-list--wrapper
  pxn-sort-co-ordinator.row
    margin: 0;
    padding-right: 0.5 * $vgu;

comment-list
  display: flex;
  flex-direction: column;
  +browser(firefox)
    flex-grow: 1;
  ul.pxn-comments
    flex: 0 1 auto;
    +browser(firefox)
      height: 0;
      +when(md)
        height: auto;
    li.pxn-comment
      border-bottom: 1px dotted #ccc;
      padding: (0.5 * $vgu) 0;
      .comment__avatar .gravatar
        width: 32px;
        height: 32px;
      .comment__content
        .comment__header
          .comment__author
            font-weight: 600;
          .comment__delete.entypo-trash:before
            display: none;
            color: #aaa;
            font-size: 1.2rem;
          .comment__updated,
          .comment__update-date
            color: #777777;
        .comment__message
          flex-direction: column;
          hr
            border-style: solid;
            border-width: 0 0 1px;
            border-color: #ccc;
          ol
            list-style-type: decimal;
            margin-left: 1.5 * $vgu;
          ul
            list-style-type: disc;
            margin-left: 1.5 * $vgu;
          img
            max-width: 100%;
        &:hover
          .comment__delete.entypo-trash:before
            display: flex;
            cursor: pointer;
      &:last-child
        border-bottom: 0;
  form
    flex: 0 1 auto;
    .input-count
      color: #777777;
      font-size: 0.9rem;
      margin-top: -0.5 * $vgu;
