@keyframes rotate-ccw {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

.branding-page
  .assets
    display: flex;
    flex-direction: column;
    // Joint styles here
    &--dir, &--asset
      display: flex;
      align-content: center;
      padding: $vgu;
      p
        margin-bottom: 0;
      .space-between
        justify-content: space-between;
    &--dir
      flex-direction: column;
      background-color: $c--black
      color: $c--offwhite
      font-weight: bold;
      padding-bottom: 0;
      text-transform: capitalize;
    &--icons
      font-weight: normal;
    &--asset
      flex-direction: row;
      background-color: $c--offwhite;
      color: $c--txt-sidebar__secondary;
      text-transform: none;
      font-weight: normal;
      padding-left: 4 * $vgu;
      margin: 0 -1 * $vgu;
      &__even
        background-color: #e3e3e3;
    &--dir-count
      align-self: flex-end;

  .uploading
    display: flex;
    padding: 1em;
    background-color: colorPalette('positive', 1);
    color: $c--offwhite;
    &--spinner
      animation: rotate-ccw 3s infinite linear;
      height: 1.5em;
      margin-right: 0.5em;
      &:before
        margin-right: 0;
    &--text
      font-weight: bold;
      margin-bottom: 0;


  .theme-viewer
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &--selector
      flex-direction: column;
    &--viewer-mode
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .colour-block
        width: 6 * $vgu;
        height: 3 * $vgu;
        border: 1px solid black;
      ul
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 0 auto;
    &--edit-mode
      display: flex;
      flex-direction: column;

  .color-picker-input-wrapper, .color-picker-input
    width: 100%;
