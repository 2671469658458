$insert-zone-overlap = 2 * $vgu

.modal__SetRepeaterX
  max-width: 40rem;

.channel-delete-bar
  background-color: colorPalette('negative', 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.5 * $vgu;
  z-index: $z-index__drag-action-zone;
  +when($sidebarBreakpoint)
    left: $sidebarWidth;

  h4
    margin-bottom: 0;
    text-align: center;
    color: $c--white;

.empty-channel
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

.channel-container
  flex-direction: row-reverse;

  .channel-helper
    flex-direction: column-reverse;
    +when(md)
      flex-direction: column;

    &--about
      flex: 1 1 auto;
      +browser(firefox)
        flex: 1 0 0;
    &--filters, &--about
      flex-direction: column;

  .channels
    margin-top: -1 * $vgu;
    padding-top: $vgu;
    max-height: 100%;

    &--no-results
      padding: 3em;
      align-self: center;
      text-align: center;

    .channel
      flex-direction: row;
      flex-wrap: nowrap;
      &-heading
        &--title
          margin: 0 $vgu $vgu 0;
          align-items: center;
        &--name
          margin-right: $vgu;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline;
          flex: 1 1 auto;
        &--count
          display: none;
          flex: 0 0 auto;
          +when(md)
            display: flex;
        &--button
          margin-right: $vgu;
      &-metadata
        &--buttons
          flex: 1;
          flex-direction: row;
          align-content: flex-end;
          pxn-button
            &:last-of-type
              margin-right: 0;
      &-slides
        &--list
          flex-direction: row;
          flex-wrap: wrap;
          position: relative;
          flex: 1 0 auto;
          &__none
            flex: 1;
            align-items: center;
            justify-content: center;
            align-self: center;
            opacity: 0.35;
      &-slide
        position: relative;
        flex-direction: column;
        margin-bottom: $vgu;
        &--title-overlay
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          bottom:0;
          left: 0;
          z-index: 1;
          align-items: center;
          pointer-events: none;
          .title
            color: $c--white;
            background-color: rgba(0, 0, 0, 0.6);
            width: 100%;
            text-align: center;
            margin: 0;
            padding: ($vgu * 0.5) $vgu;
            font-size: 1.2em;
        &--repeater
          cursor: default !important;
          overflow: hidden;
          &:hover
            & .channel-slide--repeater-hover
              opacity: 1;
          &-hover
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            color: rgba(255, 255, 255, 0.8);
            display: flex;
            opacity: 0;
            align-items: center;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.6);
            transition: opacity 1s;
            span
              cursor: pointer;
              &:before
                font-size: 2em;
                line-height: initial;
        &--info-overlay
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 (0.5 * $vgu);
          padding: (0.5 * $vgu) 0;
          background-color: colorPalette('accent', 1);
          justify-content: center;
          z-index: $z-index__content;

          &.invert-banner
            background-color: $c--white;
            color: colorPalette('accent', 1);
            border: 1px solid colorPalette('accent', 1);

            span
              color: colorPalette('accent', 1);

          span
            font-size: 1.2 * $vgu;
            color: $c--white;

          [class*='entypo-']:before
            margin-right: 0.7 * $vgu;
            font-size: 1.5 * $vgu;

        &--orientation-overlay
          display: flex;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: (0.5 * $vgu);
          padding-right: (8/7) * $vgu;
          justify-content: flex-end;
          z-index: $z-index__content;
          pointer-events: none;

          &__both
            span
              position: relative;
            span.entypo-doc
              right: 0;
              bottom: 0.5em
            span.entypo-doc-landscape
              right: -1.2em;
              bottom: 0;

          span
            font-size: 1.2 * $vgu;
            color: $c--semi-t-white;

          [class*='entypo-']:before
            margin-right: 0.7 * $vgu;
            font-size: 1.5 * $vgu;

        &--repeat-overlay
          display: flex;
          position: absolute;
          bottom: 0;
          left: -0.5 * $vgu;
          right: -0.5 * $vgu;
          margin: 0 (0.5 * $vgu);
          padding: (0.5 * $vgu) 0;
          justify-content: flex-end;
          z-index: $z-index__content;
          overflow: hidden;
          &__background
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 6em;
            width: 7em;
            height: 7em;
            position: absolute;
            right: -3.5em;
            bottom: -4.25em;

          span
            font-size: 1.2 * $vgu;
            color: $c--white;

          [class*='entypo-']:before
            margin-right: 0.7 * $vgu;
            font-size: 1.5 * $vgu;

          .repeats
            margin-right: 0.5em;
        &--insert-zone
          position: absolute;
          display: flex;
          top: 0;
          bottom: $vgu;
          width: 2 * $insert-zone-overlap;
          align-items: center;
          justify-content: center;
          right: -1 * $insert-zone-overlap;

          &:first-of-type
            left: -1 * $insert-zone-overlap;

          &.hover, &:hover
            .channel-slide--insert-zone__icon
              cursor: pointer;
              transform: scale(1);

          &__icon
            width: 1.5 * $insert-zone-overlap;
            height: 1.5 * $insert-zone-overlap;
            background-color: colorPalette('positive', 1);
            transform: scale(0);
            transition: transform $anim-duration;
            position: relative;
            border-radius: 50%;
            $shadow__standard()
            z-index: 3;

            &:before
              display: flex;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              justify-content: center;
              align-items: center;
              color: $c--white;
              font-weight: bold;

        &--drag-item
          position: absolute;
          top: 0; right: 5px; bottom: 0; left: 5px;
          background-size: contain;
          background-color: $c--bg-sidebar__dark;
          cursor: pointer;
          display: flex;
          flex-direction: row !important;
          user-select: none;
          background-repeat: no-repeat;
          background-size: cover;
          padding: 0 !important;

          &.portrait-only
            background-size: contain;
            background-position: center;

          drop-target
            position: absolute;
            top: 0; bottom: 0;
            height: 100%
            display: flex;
            flex: 1;
            margin: 0 -1 * $colGutter;
            width: 50%

            &:first-of-type
              left: 0

            &:last-of-type
              right: 0
