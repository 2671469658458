@require 'nib'
@require '../styles/_constants.styl'
@require '../styles/_utility.styl'

takeover-template
  accordion-title
    .tt--title
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    .tt--orientation-indicator
      position: relative;
      top: 0.5 * $vgu;
      color: $c--dark-input;
      width: 4 * $vgu; // Width of largest icon use
      &__both
        top: $vgu;
        .dual-orientations
          position: relative;
          width: 4 * $vgu; // Icon width + margin
          .entypo-doc, .entypo-doc-landscape
            position: absolute;
          .entypo-doc
            right: 0;
            bottom: 3 * $vgu;
  accordion-buttons
    pxn-button
      margin-left: $vgu;
  accordion-body
    display: flex;
    flex-direction: column;
    .no-bottom-margin
      margin-bottom: 0 !important;
    .no-top-margin
      margin-top: 0 !important;
    +when(md)
      flex-direction: row;
    .resource-viewer-container
      overflow: hidden;
      &.display-none-xs
        +when(md)
          display: flex;
    form.disabled > div
      padding-top: 0.5 * $vgu;

  .tt--targets
    order: -1;
    +when(md)
      order: 1;
  .tt--switchable-input-lable
    justify-content: flex-start;
    font-weight: 400;
    span
      cursor: pointer;
    span, p
      user-select: none;
    .active
      font-weight: 700;
      cursor: default;
    icon
      text-transform: none;
  .tt--show-more-less
    .entypo-down-open-big
        &::before
          margin-right: 0;
          font-size: 2.5em
          width: 1em
          height: 1em
          margin-top: -0.5 * $vgu;
        &__flipped
          transform: rotate(180deg);
          margin-bottom: 1 * $vgu;

.takeovers-list
  flex-grow: 1;
  &--scroll-elem
    height: 100%;
    min-height: 100%;
    margin-top: -1 * $vgu;
    padding-top: $vgu;

.takeover
  &-preview
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: $z-index__drag-action-zone;
    padding: 0;
    flex-direction: column;
    background-color: $c--bg-preview;
    +when(md)
      position: absolute;
      max-height: "calc(100vh - %s - %s)" % ($contentHeaderHeight 5 * $vgu);
      width: 100vh;
      max-width: 60%;
      top: $vgu;
      right: $vgu;
    &.slide-edit--preview__portrait
      width: 60%;
      +when(md)
        width: "calc((100vh - %s - %s) / 1.77777777778)" % ($contentHeaderHeight 19 * $vgu); // 10 VGU = buttons + top & btm margin.
    pxn-loading
      color: $c--offwhite;


    pxn-button[modifiers="tiny"]
      margin-right: 0;
    &--controls
      flex-wrap: wrap;
      width: 100%;
      pxn-button
        flex-grow: 1;
        margin: 0;
    &--control-row
      flex: 1 0 100%;
      flex-wrap: wrap;
    &--meta-bar
      flex-wrap: wrap;
      margin: 0;
      flex-direction: row;
    &--title, &--orientation-switch
      flex: 1 1 auto;

  &--thumbnail
    width: 100%;
    background-color: $c--bg-sidebar__dark;
    background-repeat: no-repeat;
    background-size: cover;
    align-self: center;
    // For some reason the preload transition on this makes the images 'vibrate'.
    transition: none
    // Hide any overflowing title text
    overflow: hidden;
    aspect-ratio(16, 9)
  &--meta
    flex-direction: column;
  &--heading
    min-width: 0;
    h3
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

.active-takeovers
  &.card // I hate this too! @goddak
    flex: 0 1 auto;
  &--takeover.takeover
    margin-bottom: $vgu;
    flex: 0 0 auto;
  &--container
    order: -1;
    min-height: 0;
    height: fit-content;
    +browser(safari)
      min-height: 0%;
    +when(md)
      order: 1;

.takeover
  &--create-edit-buttons.row
    flex: 0 0 auto;
  &--body
    display: flex;
    flex: 0 0 35.5em;
    padding-bottom: $vgu;
    pxn-accordion.card__shaded
      padding: 0.5 * $vgu;
      margin-bottom: $vgu;

takeover-template.takeover-template
  flex: 0 0 auto;