// Default card style
@require '_colours'
@require '_mixins'

.card
  $shadow__standard()

.card, .card__flat
  background-color: $c--bg-content;
  flex-direction: inherit;
  flex: inherit;
  flex-basis: auto;
  margin-bottom: $vgu;
  padding: $vgu;
  //: most elements have a margin-bottom.;
  padding-bottom: 0;
  &__double-padded
    padding: 2 * $vgu;

  &__quad-padded
    padding: 4 * $vgu;

  &__no-padding
    padding: 0;

  &__padded-bottom
    padding-bottom: $vgu;

  &__shaded
    background-color: $c--bg-sidebar__hover;

  &[disabled="disabled"]
    background-color: $c--shades['disabled'][0];

  &__dark
    background-color: colorPalette('grey', 1);
    color: $c--offwhite;

    h1, h2, h3, h4, h5, h6
      color: $c--offwhite;

    .checkbox--label
      color: $c--offwhite;

    .inline-help:before
      color: #98739D;
