// Use nib.
@require 'nib'
@require '_utility'

// Global reset
global-reset()

*
  box-sizing: border-box;

[disabled="disabled"]
  pointer-events: none;
  noselect()

// Import other generic files
@require '_accordions'
@require '_type'
@require '_colours'
@require '_cards'
@require '_buttons'
@require '_inputs'
@require '_responsive-grid'
@require '_lists'
@require '_tags'
@require '_tooltips'
@require '_toast'
@require '_modals'
@require '_utility'
@require '_animations'
@require '_navigation'
@require '_support'
@require '_media-cleaner'
@require '_media'
@require '_icons'
@require '_gravatar'
@require '_date-time'
@require '_deviceConfigs'
@require '_templates'
@require '_slides'
@require '../screensavers/_screensavers'
@require '_schedules'
@require '_takeovers'
@require '_pxnCalloutBanner'

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak
  display: none !important;


//: The body has a flare-body class. This ensures the body is a flexbox;
// and has a min-height of 100vh (essentially 100%).
.flare-body
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-color: $c--bg-sidebar;
  &::before
    content: "xs";
    display: none;
    +when(sm)
      content: "sm"
    +when(md)
      content: "md"
    +when(lg)
      content: "lg"
    +when(xl)
      content: "xl"

// The ui-view has this class, so it effectively wraps everything
// in the main page. We just ensure it expands to fill any remaining
// space that is has.
.flare-container
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $c--bg-main;
  position: relative;
  transition: transform $anim-duration;

// The content area should just expand to fit whatever is left.
#content, .content-container
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $c--bg-main;
  overflow: hidden;

#content
  padding: $vgu;
  padding-bottom: 0;
  flex: 1 1 auto;
  max-height: "calc(100vh - %s)" % ($contentHeaderHeight);
  +when(sm)
    padding-right: 1.25 * $vgu;

.content-container
  max-width: 100vw;
  box-sizing: border-box;
  +when(lg)
    max-width: "calc(100vw - %s)" % ($sidebarWidth)

.copyright
  position: relative;
  align-self: center;
  z-index: 100;

  &__light
    color: $c--offwhite;
    font-weight: bold;

pre.debug
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 40vw;
  font-family: monospace;
  font-size: 12px;
  max-height: 100vh;
  overflow-y: scroll;

pre.mono
  font-family: monospace;

.half-opacity
  opacity: 0.5;

// Import(ant) files
@import '_chartist-customisations'
@import '_ps-customisations'
@import '_dir-pagination'

// View imports
@import '../accounts/_users'
@import '../accounts/_accounts'
@import '../channels/_channels'
@import '../devices/_devices'
@import '../feeds/_feeds'
@import '../ownership/_ownership'
@import '../playlists/_playlists'
@import '../settings/_settings'
@import '../slides/_slides'
@import '../tickers/_tickers'
@import '_login'
@import '_dashboard'
@import '../uploads/_uploads'
@import '../branding/_branding'
@import '../groups/_groups'
@import './_bulk-actions'
@import '../onboarding/_onboarding'
@import '_easterEggs'
@import '../../receiver/pxn-drag-and-drop-direction-classes'
@import '../template-browser/_template-browser'
@import '../web-receiver/_web-receiver'
@import './_pxn-carousel.styl'
@import '../localisation/_localisation'
@import '_previewRole'
@import '../device-stats/_device-stats'
