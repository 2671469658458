@require '_inputs'
@require '_colours'
@require '_modals'

$library-height__media = 30rem;

.manage-media-container
  max-height: "calc(100vh - %s - %s)" % ($contentHeaderHeight $vgu);
  flex: 1 0 auto;
  .main-card
    flex: 1 1 auto;

.media-input
  &--thumbnail
    background-color: black;
    margin-bottom: $vgu;
    height: 20 * $vgu;
    &__table-input
      height: 11 * $vgu;
      margin-bottom: 0.5 * $vgu;

  &--colour-picker
    flex-direction: column;
    h5
      margin-bottom: 0;
  &--no-image
    height: 6 * $vgu;
    color: colorPalette('grey', 4);
    text-transform: uppercase;
    &__table-input
      height: auto;
  &--name
    padding-right: $vgu;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  flex: 0 1 auto;

.media-scroll-container
  flex: 1 1 0;
  width: 100%;
  flex-direction: column;

.card-title
  flex: 0 0 auto;

.media-library
  width: 100%;
  padding: 0.5 * $vgu;
  background-color: colorPalette('neutral', 3);
  position: relative;
  margin-bottom: $vgu;
  flex: 1 1 auto;
  &--container
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
  &--showing
    flex: 0 0 auto;
    justify-content: center;
    width: 100%;
  &--more
    flex: 0 0 auto;
    width: 100%;
  &--form
    display: flex;
    flex-direction: column;
  &__modal
    height: 32rem;
    +when(md)
      height: $library-height__media;
  &--media-list
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;
  &--grouping-header
    margin-right: $colGutter;
  &--filters
    width: 100%;
    padding-left: $colGutter;
    display: flex;
    flex: 0 0 auto;
    pxn-tag-input
      background-color: $c--white;
    .tag-input
      background-color: transparent;
  &--group
    flex: 0 0 auto;
    &__highlight
      margin: 0.5 * $vgu;
      padding: 0.5 * $vgu;
      background-color: $c--shades['grey'][3];
  &--separator
    margin-left: 1 * $vgu + $colGutter;
    margin-top: $vgu;
    margin-bottom: 0;
    &__no-top
      margin-top: 0;
  &--item
    width: 25%;
    +when(sm)
      width: 20%;
    +when(md)
      width: 15%;
      ^[0]__modal ^[1]
        width: 20%;
    +when(lg)
      width: (100/12)%;
      ^[0]__modal ^[1]
        width: 20%;
  &--thumbnail-border
    position: relative;
    &__selected
      background-color: colorPalette('accent', 1);
    &__sharedWithMe
      background-color: $c--black;
  &--thumbnail
    margin: 0.5 * $vgu;
    background-color: black;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &__shared
      &:after
        background-color: $c--black;
        border-radius: $vgu;
        text-align: center;
        font-family: 'entypo';
        width: 2 * $vgu;
        height: 2 * $vgu
        position: absolute;
        bottom: 0.25 * $vgu;
        right: 0.25 * $vgu;
        content: '\1f465';
        color: $c--offwhite;

    &__shared2
      &:after
        background-color: $c--black;
        border-radius: $vgu;
        text-align: center;
        font-family: 'entypo';
        width: 2 * $vgu;
        height: 2 * $vgu
        position: absolute;
        bottom: 0.25 * $vgu;
        right: 0.25 * $vgu;
        content: '\1f465';
        color: $c--offwhite;

    &__video
      justify-content: center;
      align-items: center;
      padding: $colGutter;
      &:before
        display: block;
        content: '\1f3ac';
        color: rgba(255,255,255, 0.75)
        font-family: 'entypo'
        position: absolute;
        bottom: 0.3em;
        right: 0.2em;
        font-size: 6vw;
        +when(sm)
          font-size: 7vw;
        +when(md)
          font-size: 6vw;
          // ^[0]__modal ^[1]
          //   font-size: 7.5vw;
        +when(lg)
          font-size: 2.5vw;
          // ^[0]__modal ^[1]
          //   font-size: 7.5vw;
        +when(xl)
          font-size: 3vw;
          // ^[0]__modal ^[1]
          //   width: 7.5vw;
  &--upload-button
    position: absolute;
    right: -100%;
    bottom: 0;
    left: 100%;

  &--preview
    background-color: colorPalette('neutral', 3);
    padding: $vgu;
    padding-bottom: 0;
    flex-direction: column;
    //: border 2px solid $c--black;

    &-close-button
      position: absolute;
      top: 0;
      right: 0;
      margin: $vgu;
      background-color: rgba($c--white, 0.5);
      z-index: $z-index__modal-content-overlay;

    &-image
      aspect-ratio(16, 9);
      width: 100%;
      background-color: black;
      display: flex; // Overide display block that is set by aspect-ration(16,9)

      video
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        max-height: 100%;
        width: 100%;

        .modal__select-media &
          position: static;

    &-details
      flex: 0 0 auto;
      flex-direction: column;
      max-height: 18 * $vgu;
      +when(sm)
        max-height: 9 * $vgu;
      +when(md)
        max-height: 6 * $vgu;

      .icon-list--item
        max-width: 10rem;
        text-overflow: ellipsis;
        white-space: nowrap;

  &--upload
    background-color: $c--white;
    // padding: $vgu;
    padding-bottom: 0;
    flex-direction: column;

    &-item
      flex: 0 0 auto;
      padding: $vgu;
      margin-bottom: $vgu;
      background-color: colorPalette('grey', 2)

    &-item, & .labelled-input--label-content:after
      color: $c--offwhite;

    &-list
      overflow-y: auto;

    &-tags
      margin-bottom: $vgu;

    &-save-button
      margin-bottom: -1 * $vgu;

  &--show-more
    margin-left: $vgu * 2;
    overflow: hidden;
    white-space: nowrap;

.upload-progress
    &--upload
      &-stat
        &__filename
          word-break: break-all;
        &__bytes
          margin-left: $vgu;
          min-width: auto;
          white-space: nowrap;

      &-status-text
        &__complete
          text-align: center;
          // I get to put a comment here as I fixed Ed's styling mistake.
          // Haha, how annoying bro!

pxn-media-upload
  height: 3 * $vgu;
  display: flex;
  label
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    span
      display: flex;
      .input-label
        min-width: fit-content;
        margin-right: 0.714285714rem;
        align-items: center;
    pxn-button
      margin-bottom: 0;
  owner-input
    select
      margin-bottom: 0;
