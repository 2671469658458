pxn-carousel
  display: flex;
  flex-direction: column;
  width: 100%;
  user-select: none;
  &.vertical-carousel
    flex-direction: column;
    padding: 0;
    margin: 0 $vgu 0 2 * $vgu;
    width: 100%;
    & .carousel
      padding: 0;
    & .list-container
      flex-direction: column;
      & .banner-image
        flex: 1 1 auto;
  .carousel
    flex: 0 0 auto;
    width: 100%;
    padding: $vgu 0 $vgu 0;
    .arrow-container
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &__disabled
        // needs styling
        cursor: pointer;
        color: lighten(#000, 60%);
      &__left
        margin-right: 0.2 * $vgu;
      &__right
        margin-left: 0.2 * $vgu;
      .arrow-left, .arrow-right
        padding-left: $vgu;
    .list-container
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;
      justify-content: flex-start;
      &.slideshow
        flex: 1 0 auto;
      .spacer-due-to-ps
        flex: 0 0 auto;
        width: 0.1 * $vgu;
      &.ps--active-x
        padding-bottom: $vgu;
      .ps__rail-x
        height: $vgu;
        &:hover .ps__thumb-x
          height: $vgu;
          bottom: 0;
    .banner-image
      background-color: rgba(#ddd, 0.3);
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0;
      margin: 0.4 * $vgu;
      $shadow__standard();
      transition: box-shadow 0.2s ease-in-out;
      cursor: pointer;
      &.cursor-default
        cursor: default;
      &.selected
        box-shadow: 1px 1px 5px 2px colorPalette('accent', 2);
      &.contain-images
        background-size: contain;
      .deprecated-resource
        padding: 0.5 * $vgu;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: none;
        justify-content: center;
        background-color: rgba($c--shades['negative'][0], 0.85);
        color: #FFF;
        +when(sm)
          display: flex;
      .friendly-name
        padding: $vgu;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        justify-content: center;
        +when(sm)
          display: flex;
        &.image
          background-color: rgba(0, 0, 0, 0.75);
          color: #FFF;
      .web-receiver-slide-info
        text-align: left;
        padding: 0.5 * $vgu;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        background-color: rgba(#fff, 0.7);
        opacity: 0;
        transition: opacity .2s ease-in-out;
        +when(sm)
          display: flex;
        &--icon
          display: flex;
          flex: 0 0 auto;
        .duration:before
          margin: 0 0.9 * $vgu 0 0.1 * $vgu;
        &.visible
          opacity: 1;
      &.web-receiver-mod:hover .web-receiver-slide-info
        opacity: 1;
      &.odd.no-image
        background-color: #999;
      &.even.no-image
        background-color: #ccc;
      &__no-result
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: auto;

// Ribbons
.ribbon
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
  & span // Classes .green and .purple
    font-size: 0.9 * $vgu;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 2 * $vgu;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 10 * $vgu;
    display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
    &.green
      background: #2e9b5b;
      background: radial-gradient(#2e9b5b 0%, #79A70A 100%);
    &::before
        content: "";
        position: absolute; left: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid #79A70A;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #79A70A;
      &::after
        content: "";
        position: absolute; right: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #79A70A;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #79A70A;
    &.purple
      background: #7D0079;
      background: radial-gradient(#C900C3 0%, #7D0079 100%);
      &::before
        content: "";
        position: absolute; left: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid #7D0079;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #7D0079;
      &::after
        content: "";
        position: absolute; right: 0px; top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #7D0079;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #7D0079;
