pxn-callout-banner
  .banner
    padding: 1em;
    transition: height 3s;
    margin-bottom: $vgu;
    &--info
      fullColorPalette('positive', 3);
    &--warning
      fullColorPalette('warning', 2);
    &--message
      flex: 1;
      margin-bottom: $vgu;
      &::before
        margin-right: 1.5 * $vgu;
        margin-left: 0.5 * $vgu;
      +when(sm)
        margin-bottom: 0;
        margin-right: $vgu;
    &--button
      margin-left: 0;
      +when(sm)
        margin-left: $vgu;