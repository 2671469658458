.login

  &-form
    max-width: 25rem;
    position relative
    margin-bottom: 10rem;

    &--toaster-container
      position: absolute;
      top: 0; right: 0; left: 0;
      z-index: $z-index__modal-toast;

      pxn-toaster
        flex: 1;

    &--card
      padding: 2 * $vgu;
      $shadow__extra()
      flex-direction: column;
      z-index: $z-index__modal-content;

      +when(sm)
        padding: 3 * $vgu;

    &--login-button
      margin-top: 0.5 * $vgu;

    &--forgotten-password
      display: flex;
      margin-bottom: 3 * $vgu;

    &--password-requirement
      li
        color: $c--shades['negative'][0];
        .comply
          color: colorPalette('positive', 1);

  &-backdrop
    background-color: white;
    background-image: url('../img/splash.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: bottom left;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

  &-10-years-banner
    width: 40rem;
    height: 10rem;
    background-image: url('../img/10-years.png');
    background-size: contain;
    background-repeat: no-repeat;

.ie-warning-toast-link
  color: white;
  text-decoration: underline;