@require '_constants'
@require '_colours'

@font-face
  font-family: 'Lato';
  font-weight: 400;
  src: url('../fonts/Lato-Regular.ttf');

@font-face
  font-family: 'Lato';
  font-weight: 700;
  src: url('../fonts/Lato-Bold.ttf');

$font-main      = 'Lato', sans-serif;
$font-headings  = 'Montserrat', sans-serif;

.font__main
  font-family: $font-main;

// Body:

html, body
  font-family: $font-main;
  font-size: $font-size-base;
  line-height: $line-height-body;

// Headings:
h1
  font-size: ($font-size-base * $goldenRatio) * 1.35;
  line-height: 4 * $vgu;
  margin-top: $vgu;
  margin-bottom: 2 * $vgu;
  font-family: $font-headings;

h2
  font-size: $font-size-base * $goldenRatio;
  line-height: 3*$vgu;
  margin-bottom: $vgu;
  font-family: $font-headings;

h3
  font-size: 1.25rem;
  line-height: 3 * $vgu;
  margin-bottom: $vgu;
  font-family: $font-headings;

h4
  line-height: 2 * $vgu;
  margin-bottom: $vgu;
  //: font-weight bold;
  font-family: $font-headings;

h5
  line-height: 2*$vgu;
  margin-bottom: $vgu;
  font-size: 0.8rem;
  text-transform: uppercase;

// Other type blocks
p
  margin-bottom: $vgu;

aside
  font-size: 0.8rem;
  line-height: 1*$vgu;

footer
  line-height: 3 * $vgu;

span
  line-height: 2 * $vgu;

a
  line-height: 2 * $vgu;
  color: $c--txt-link;
  text-decoration: none;
  cursor: pointer;

  &.subtle
    color: $c--txt-link__subtle;

strong
  font-weight: bold;

em
  font-style: italic;

hr
  width: 100%;

// Text effects
.text__subtle
  // text-shadow: -1px -1px 1px #fffe, 1px 1px 1px #0008;
  color: rgba($c--black, 0.2);
  // opacity: 0.4;
.text
  &__empty
    color: colorPalette('grey', 4)
    font-style: italic;
  &__no-match
    color: colorPalette('grey', 4)

.text__normal
  color: $c--txt-main;

.text__white
  color: $c--offwhite;

.text__red
  color: colorPalette('negative', 0)
  font-style: italic;

.cardless-heading
  font-weight: normal;
  margin: 2 * $vgu 0;
  color: colorPalette('disabled', 1);

  &.__shaded
    color: $c--txt-main;


.text__align-right
  text-align: right;

// forms
button, .standard-button
  line-height: 2 * $vgu;

input, textarea
  line-height: 2 * $vgu;
  font-size: $font-size-base;
  font-family: $font-main;

// Icon fonts
// Anything with a class starting 'icon-'
[class*='entypo-']:before
  font-family: 'entypo';
  line-height: 2 * $vgu;
  font-size: 2 * $vgu;
  margin-right: $vgu;
  width: 2 * $vgu;
  height: 2 * $vgu
  text-align: center;

// Text alignment
.text-align__center
  text-align: center;

// Font weights
.font-weight__bold
  font-weight: bold;

// Filter reset text
.filter-reset
  align-self: flex-end;
  line-height: 3 * $vgu;
  margin-left: $vgu;
  cursor: pointer;
