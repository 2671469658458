.preview-role
  position: absolute;
  z-index: $z-index__modal-content-overlay;
  justify-content: center;
  align-self: center;
  &--top
    top: 6 * $vgu;
  &--bottom
    bottom: 6 * $vgu;
  &--bar
    background-color: $c--black;
    color: $c--white;
    padding: $vgu;
    &__button
      flex: 0 0 auto;
      color: $c--white;
      align-items: center;
    &__message
      align-items: center;
      padding-left: $vgu;
      color: $c--white;
    &__placement-top, &__placement-bottom
      flex: 0 0 auto;
      cursor: pointer;
      align-items: center;
      height: 100%;
      &::before
        margin-left: $vgu;
        padding-left: $vgu;
        border-left: 1px solid $c--white;
