.scroll-element
  position: relative;
  &.scrollbar__active:not(.modal--content)
    padding-right: 0;
    +when(sm)
      padding-right: 1.5 * $vgu;

[pxn-scroll-element]
  position: relative;
  &.scrollbar__active:not(.modal--content)
    padding-right: 1.5 * $vgu;
  &.flex-grow-1
    flex-grow: 1;

.scroll-page-container
  flex: 1 1 auto;
  +when(sm)
    max-height: "calc(100vh - %s - %s)" % ($contentHeaderHeight $vgu);

.list-scroll-container
    +browser(edge)
      height: 0;
  .list-scroll-item
    padding-bottom: 1px; // Because of weird PS .5 measurement causing 1px variants based on rounding.
    padding-right: 1.5 * $vgu;
    padding-bottom: 0;
    +browser(edge)
      max-height: 100%;

.ps
  // Using transform promotes the button to a new layer and so will not be
  // visible outside of its container, scroll-position fixes this.
  will-change: scroll-position;

  &__rail-x, &__rail-y
    background-color: #C5C5C5;
    opacity: 0.8;
    transition: width 0.2s ease-in-out, right 0.2s ease-in-out, opacity 0.2s ease-in-out;
    width: $vgu;

  &__thumb-x, &__thumb-y
    transition: width 0.2s ease-in-out, right 0.2s ease-in-out;
    background-color: #777777;

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y
  background-color: #C5C5C5;

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y
    opacity: 1;

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking
    background-color: #B5B5B5;

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x
    background-color: #5A5A5A;
    height: "calc(%s - %s)" % ($vgu 2px);

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y
    background-color: #5A5A5A;
    width: "calc(%s - %s)" % ($vgu 2px);
    right: 1px;

@supports(-ms-overflow-style: none)
  .ps
    overflow auto !important

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
  .ps
    overflow auto !important

.ps .scroll-element, .ps .scroll-page-container, .ps .list-scroll-item
  &__flat-card
    .ps__rail-x, .ps__rail-y
      background-color: #B5B5B5;
    .ps__thumb-x, .ps__thumb-y
      background-color: #777777;
    .ps--active-x > .ps__rail-x,
    .ps--active-y > .ps__rail-y
      background-color: #B5B5B5;
    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking
      background-color: #A5A5A5;
    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x
      background-color: #5A5A5A;
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y
      background-color: #5A5A5A;

  &__card
    .ps__rail-x, .ps__rail-y
      background-color: #E5E5E5;
    .ps__thumb-x, .ps__thumb-y
      background-color: #999999;
    .ps--active-x > .ps__rail-x,
    .ps--active-y > .ps__rail-y
      background-color: #D5D5D5;
    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking
      background-color: #C5C5C5;
    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x
      background-color: #999999;
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y
      background-color: #999999;

  &__secondary-nav
    &.scrollbar__active
      +when(sm)
        padding-right: 0;
    .ps__rail-x, .ps__rail-y
      background-color: #B5B5B5;
    .ps__thumb-x, .ps__thumb-y
      background-color: #777777;
    .ps--active-x > .ps__rail-x,
    .ps--active-y > .ps__rail-y
      background-color: #B5B5B5;
    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking
      background-color: #A5A5A5;
    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x
      background-color: #5A5A5A;
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y
      background-color: #5A5A5A;
