.web-receiver-page
  display: block;
  height: "calc(100vh - %s - %s)" % ($contentHeaderHeight 2 * $vgu);
  .web-receiver
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto minmax(30 * $vgu, 1fr) auto;
    grid-template-areas: "form" "slideshow" "carousel";
    grid-gap: $vgu;
    +when(lg)
      height: 100%;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto minmax(30 * $vgu, 1fr);
      grid-template-areas: "form form" "slideshow carousel";

    &--form
      grid-area: form;
      .config
        display: flex;
        flex-direction: column;
        max-width: 100%;
        &--reset
          align-self: center;
          cursor: pointer;
      .locale-input
        min-width: 0;
        max-width: 100%;
      .full-width-xs
        width: 100%;
        margin-left: 0;
        +when(sm)
          width: unset;
          margin-left: $vgu;


    &--slideshow
      grid-area: slideshow;
      justify-content: center;
      align-items: center;
      position: relative;
      max-height: 100%;
      overflow: hidden;
      background-color: $c--bg-preview;
      position: relative;
      &.fullscreen
        slide-hover-overlay:not(.show)
          cursor: none;
          .slide-hover-overlay--buttons__button
            cursor: none;
      &__landscape
        width: 100%;
      &__portrait
        height: 100%;
      &__landscape, &__portrait
        position: relative;
        background-color: $c--bg-preview;
        user-select: none;
        #iframe-slideshow
          display: flex;
          flex: 1;
          width: 100%;
          height: 100%;
          pointer-events: none;
          position: relative;
    & .card
      margin: 0;

    &--carousel
      grid-area: carousel;
      width: 100%;
      +when(lg)
        min-width: 20 * $vgu;
      .repeaters, .non-repeaters
        text-align: left;
        & .heading
          padding-left: 3.5 * $vgu;
        +when(lg)
          text-align: center;
          min-width: 20 * $vgu;
          & .heading
            padding-left: 0;
      pxn-carousel
        margin: 0;
        height: 100%;
        &.vertical-carousel
          flex-direction: column;
          padding: 0;
          flex: 1;
          height: 100%;
          & .carousel
            flex-direction: column;
            padding: 0;
            max-height: 100%;
          & .list-container
            flex-direction: column;

slide-hover-overlay
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 5%, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 40%);
  opacity: 0;
  transition: opacity .3s ease-in-out;
  &.show
    opacity: 0.8;
  .slide-hover-overlay--buttons
    position: absolute;
    bottom: 0;
    justify-content: flex-end;
    width: 100%;
    padding: 3em;
    color: $c--white;
    &__button
      opacity: 0.8;
      transition: opacity .3s ease-in-out;
      padding: 1em;
      margin-left: 0.8em;
      &::before
        margin: 0;
      &:hover
        cursor: pointer;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;