.localisation
  &--locales
    order: 1;
    +when(md)
      order: 0;

.locale-tree
  flex: 1 1 auto;

[pxn-scroll-element].locale-tree.compensate-scrollbar.scrollbar__active
  padding-right: $vgu;

pxn-locale-tree, .angular-ui-tree-drag
  .ui-tree-list
    width: 100%;
    &.main-locale
      margin: 0;
      .angular-ui-tree-nodes.angular-ui-tree-nodes
        margin-left: 2 * $vgu;
        padding: 0;

  .ui-tree-root .handle
    cursor: grab;
    cursor: -webkit-grab;
    width: $vgu;
    height: 3 * $vgu;
    margin-right: 0.5 * $vgu;
    background-image: url('../img/drag-handle.png');
    background-size: contain;
    background-repeat: repeat;

  .tree
    &--overview
      .placeholder-text
        font-style: italic;

  .item-container
    &.selected
      background-color: colorPalette('selected', 1);
    .item
      align-items: center;
      padding: 0.5 * $vgu;
      &.locale-manager, &.locale-selector
        cursor: pointer;
        user-select: none;
        &.no-children:not(.cannot-select)
          cursor: pointer;
        &.has-content
          .node-name
            font-weight: bold;
        &.highlight
          background-color: colorPalette('selected', 1);
        .content-global::before
          margin-right: 0.5 * $vgu;
        .content
          &-localised, &-disabled, &-global
            flex: 0 0 auto;


      &:not(.locale-manager):not(.locale-selector)
        padding: 0.5 * $vgu;
        margin-bottom: 0.5 * $vgu;
        background: $c--white;
        min-height: 4 * $vgu;
        &.no-children
          cursor: auto;
          .handle
            margin-right: 1.5 * $vgu;
        &.spacer
          padding-left: 1.5 * $vgu;
      &--buttons
        &__button:not(:last-child)
          margin-right: 0.5 * $vgu;

      &.no-match, &.cannot-select, &.slide-disabled
        color: #999;
        &.highlight
          color: unset;
        & .select-linking-message
          text-align: center;
          color: colorPalette('warning', 1);
      &.cannot-select
        cursor: not-allowed;

      .node-name
        flex: 1 1 auto;

      .status-icon
        flex: 0 0 auto;
        transform: rotate(0);
        // &::before
        //   transition: transform .2s ease-in-out;
        &.collapsed::before
          transform: rotate(-90deg);
      .handle, .leaf
        flex: 0 0 auto;

    .item-info
      margin: 0 $vgu;
      &--list
        padding-top: 0.5 * $vgu;
        margin-left: 2 * $vgu
        &__item
          list-style-type: disc;


  .angular-ui-tree-placeholder
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    box-sizing: border-box;
    margin: 0;

.ps__rail-x
  height: $vgu;
  .ps__thumb-x
    height: 0.8 * $vgu;
    top: 1px;
    bottom: 1px;

.onboarding-manage-locales-behaviour pxn-button[modifiers="tiny"]
  margin-right: 0;
  box-sizing: border-box;

localisation-info
  flex: 0 1 auto;
  margin-bottom: 0.8 * $vgu;
  .pending
    color: colorPalette('warning', 1);
  [class*='entypo-']:before
    margin-right: $vgu;
    font-size: 1.5 * $vgu;
