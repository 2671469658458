@import 'nib'

.pxn-aspect-wrapper
  position: relative;
  display: inline-block;

.pxn-aspect-child
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;


$supportedAspects = '16-9', '9-16', '4-3', '1-1';

for $aspect in $supportedAspects
  $split = split('-', $aspect);
  $width = convert($split[0]);
  $height = convert($split[1]);
  $aspectRatio = $height/$width * 100;

  .pxn-aspect-{$aspect}:after
    content: '';
    display: block;
    padding-top: $aspectRatio + '%';
