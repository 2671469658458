@require '_type'
@require '_colours'
@require '_constants'

.inline-help
  //: The icon itself;
  display: inline;
  line-height: $line-height-body;
  position: relative;
  margin-left: 0.5 * $vgu;
  text-transform: none;
  // left: $colGutter * 2;
  &:hover
    &:before
      color: rgba(colorPalette('accent', 3), 1);
  &:before
    display: block;
    font-size: 1rem;
    line-height: $line-height-body;
    font-family: 'entypo';
    //: this is the entpyo info circled thing;
    //: e704 circled ?, 2753 ? e718 speech bubble;
    content: '\e704';
    transition: color $anim-duration;
    color: rgba(colorPalette('accent', 2), 0.25);

  &__warning:before
    content: '\26a0';
    color: rgba(colorPalette('warning', 1), 0.25)
  &__warning:hover, &__warning--strong
    &:before
      color: rgba(colorPalette('warning', 1), 1)

  &__large
    &:before
      font-size: 1.5rem;

  &__centered
    display: flex;
    justify-content: center;
    align-items: center;


  &__card
    position: absolute;
    display: block;
    top: $vgu;
    right: $vgu;
    left: auto;

  &__light:before
    color: rgba($c--white, 0.4);

  &__content-field
    display: block;
    position: absolute;
    top: -0.5 * $vgu;
    right: 0;
    z-index: $z-index__tooltip;

   &__input-label
    text-transform: none;
    display: inline-block;
    position: relative;
    top: -0.5 * $vgu;

  &__inline
    &:before
      display: inline;
