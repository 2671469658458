.device-stats
  flex: 0 1 auto;
  .bold
    font-weight: bold;
  &--device-list
    order: 1;
    +when(md)
      order: 0;
    .no-stats-message
      margin-top: 3em;
    .device
      position: relative;
      padding-left: 1em;
      &-details
        &--name
        .is-locked:before
          font-size: 1em;
          margin-right: 0.5 * $vgu;
        &--list
          margin-left: 0.4em;
          &__item
            .list-item-icon
              width: 1.6rem;
            &.spacing-margin
              margin-bottom: 0.5 * $vgu;
            & :before
              font-size: 1rem;
      &:before
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0.5em;
        height: 100%;
        background-color: colorPalette('negative', 1);
        margin-right: $vgu;
      &.online:before
        background-color: colorPalette('positive', 1);
      &.locked:before
        background-image: linear-gradient(transparent 50%, colorPalette('warning', 1) 50%);
        background-size: 0.5em 0.8em;
      .status-indicator
        position: relative;
        margin-right: 0.4 * $vgu;;
        margin-bottom: 0.5 * $vgu;
        flex: 1;
        height: 1.5em;
        &.offline
          background-color: $c--shades['grey'][3];
        &.online
          background-color: colorPalette('positive', 1);
        &.both
          background-color: #ECBF87;
        &.future
          border: 1px solid $c--shades['grey'][3];
        &.offline, &.online, &.both, &.future
          &:hover, &.selected
            background-color: $c--accent-1;
        .info-tooltip
          display: none;
          .date
            white-space: nowrap;
          .time-event
            white-space: nowrap;
        &:hover .info-tooltip
          padding: $vgu;
          display: flex;
          position: absolute;
          z-index: $z-index__content;
          &.open
            &--up
              bottom: 100%;
            &--down
              top: 2em;
            &--left
              right: 0;
            &--right
              left: 0;

      .event-details
        width: 100%;
        &--event
          display: grid;
          grid-template-columns: repeat(2, max-content);
          grid-template-rows: auto;
          grid-template-areas: "timeFriendlyEvent" "systemInfo";

          &__time-friendly-event
            grid-area: timeFriendlyEvent;
            & .time
              margin-right: 0.5 * $vgu;
          &__system-info
            grid-area: systemInfo;
            margin: 0 0 $vgu $vgu;
            +when(md)
              margin-bottom: 0;
            flex-direction: column;
            +when(sm)
              flex-direction: row;
            & .event
              margin-right: 0.5 * $vgu;
            & .hostname
              margin-right: 0.5 * $vgu;
            & .event, & .hostname, & .socket
              font-family: monospace;

          +when(md)
            grid-template-columns: max-content 1fr max-content;
            grid-template-areas: "timeFriendlyEvent . systemInfo"

  &--filters
    flex: 0 0 100%;
    order: 0;
    +when(md)
      order: 1;

    .local-time-switch .checkbox__switch
      margin-top: 0.5 * $vgu;

    .timezone-select[disabled="disabled"] > select
      background-color: colorPalette('disabled', 1);
      color: colorPalette('disabled', 2);
      border: none;