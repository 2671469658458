.screensaver-grid
  display: grid;
  grid-template-columns: repeat(5, auto);
  margin-bottom: 1 * $vgu;
  grid-row-gap: 3 * $vgu;
  grid-column-gap: 2 * $vgu;

  &__cell
    padding: 0 1 * $vgu;

  &__divider
    grid-column: 1 / -1;
    height: 1px;
    background-color: #ccc;

  &__description
    color: colorPalette('grey', 2);
    max-width: 28rem;

  &__disabled-row
    color: colorPalette('grey', 3);

    .screensaver-grid__description
      color: colorPalette('grey', 4);

  &__disabled-icon
    position: relative;

    // top: 0.15 * $vgu;
    &:before
      font-size: 1rem;

  &__empty
    grid-column: 1 / -1;
    flex-direction: column;
    justify-self: center;
    justify-items: center;
    align-items: center;
    max-width: 28rem;
    margin-left: 1 * $vgu;
    opacity: 0.8;
