.tickers-page
  min-height: 0;
  max-height: 100%;
  flex: 1 1 auto;
  margin: 0 -0.357142857rem; // Like a row, but not a row.
  +when(xs)
    flex-direction: column;
  &--column
    min-height: 0;
  &--filters
    flex-direction: column;
  &--list-container
    flex: 1 1 auto;
    min-height: 0;
    padding: 0 $vgu $vgu;
    &.ps--active-y.scrollbar__active.ps
      padding-right: 2 * $vgu; // override PS defaults
  &--list
    flex: 1 1 auto;
    flex-direction: column;
    margin-left: -0.357142857rem; // Like a row, but not a row.
    margin-right: -0.357142857rem; // Like a row, but not a row.
  &--list-item
    cursor: pointer;
    flex: 0 0 auto;
    &__no-items
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover
        background-color: $c--bg-content !important;
        cursor: default;
    &:hover
      background-color: colorPalette('selected', 1);
    &__selected
      background-color: colorPalette('selected', 1);
  &--no-selection
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
  &--dnd-placeholder
    background-color: $c--txt-sidebar;
    height: 4 * $vgu;
    justify-content: center;
    align-items: center;

.ticker-list
  &--headings
    align-items: center;
    position: sticky;
    top: 0;
    padding-top: $vgu;
    z-index: 1; // Over pxn-tooltips
    background-color: $c--white;
  &--item
    flex-wrap: wrap;
    &-col
      padding: 0.5 * $vgu;


$buttonHeight = 4 * $vgu;
pxn-ticker-detailed.card
  flex: 0 1 auto;
  min-height: 0; // Actually let it shrink.
  form
    height: 100%;
    display: flex;
    flex-direction: column;
  ul
    li:last-of-type
      div
        padding-bottom: 0;
  .ticker-messages
    &--heading
      margin-bottom: 0.5 * $vgu;
    &--container
      width: 100%;
      flex: 0 1 auto;
      min-height: 0;
      li.row.dndDraggingSource
        display: none;
  .add-item-button
    margin: $vgu 0;
    flex: 0 0 auto;
    width: 100%;
  pxn-dropdown-menu
    margin-right: 0.5 * $vgu;
  .ticker
    &--messages
      width: 100%;
      flex: 0 0 auto;
    &--continuous-display
      margin-top: 1.5 * $vgu;

.ticker-detail-overlay
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  .card
    margin-bottom: 0;

pxn-ticker-preview
  font-family: 'Source Sans', sans-serif;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  &.ticker
    &__top
      top: 0;
      transform: translateY(-7em);
    &__bottom
      bottom: 0;
      top: unset; // overide pxn-aspect-child style
      transform: translateY(7em);
    &__bottom, &__top
      &.ticker__animate-in
        transition: transform 0.5s linear, opacity 0.5s ease-in;
        transform: translateY(0em);
        opacity: 1;
  .ticker
    &--messages
      background-color: #FFF;
      height: 2em;
      justify-content: center;
      align-items: center;
      padding: 0.5em;
    &--message
      font-size: 1em;
      text-overflow: clip;
      white-space: nowrap;

pxn-ticker-list-item:last-of-type
  box-sizing: border-box;
  border-bottom: 1px solid #eee;