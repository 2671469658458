@require '_cards'

.pagination
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $vgu;
  flex: 1 0 auto;

  &--number-container
    flex: 1;

  &--backward-container
    flex-shrink: 0;
    margin-right: $vgu;

  &--forward-container
    flex-shrink: 0;
    margin-left: $vgu;

  &-link
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 2 * $vgu;
    user-select: none;

    &__active
      a
        font-weight: bold;

    &__disabled
      a
        color: colorPalette('disabled', 2);

    &__active, &__disabled
      pointer-events: none;
      cursor: default;

    &__boundary, &__direction
      position: relative;
      top: -2px;
      font-size: 1.5em;
      margin: 0 $vgu;

    &__number
      border-right: 1px solid colorPalette('disabled', 2);
      &:last-of-type
        border: 0;
