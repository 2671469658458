@require '_constants'

.manageAccounts
  &--grid
    display: grid;
    align-items: center;
    grid-auto-flow: dense;
    grid-template-columns: 1fr 1fr;
    column-gap: 2 * $vgu
    +when(sm)
      grid-template-columns: auto auto 1fr auto 1fr;
      row-gap: 0.5 * $vgu;

  &--accountName
    grid-column: 1 / span 2;
    justify-self: center;
    margin-top: 3 * $vgu;
    &:first-of-type
      margin-top: 0;
    +when(sm)
      grid-column: 3;
      justify-self: auto;
      margin-top: 0;



  &--checkbox-cell, &--header-cell
    justify-self: center;
  &--licence-bar
    position: relative;
    top: -3px;
