share-indicator
  align-self: center;
  margin-bottom: $vgu;
.share-indicator:before
  font-size: 1.2rem;

.dependency-tree
  &--list-item
    for i in 0..5
      &__depth-{i}
        margin-left: $colGutter * i * 3;
    &__unshareable
      color: $c--shades['negative'][0];
  &--item
    &-type
      font-weight: bold;
      text-transform: capitalize;
