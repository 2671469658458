@require '_colours'

bulk-option-button > pxn-dropdown-menu
  display: flex;
  & .selected-count
    padding-left: 0.5 * $vgu;
  & > pxn-button
    flex: 1;
  &.dropdown-menu__menu-down > ng-transclude
    top: 3 * $vgu;

bulk-delete-checkbox.checkbox
  margin-right: 0.4 * $vgu;
  &.no-right-margin
    margin-right: 0;
  .mass-delete > .checkbox.__icon-check
    margin-top: -1 * $vgu;
    margin-bottom: 0;
  // &__small
  //   height: 0 * $vgu;
  //   transform: scale(0.7);
  //   transform-origin: left;
  //   & > pxn-checkbox label.__icon-check
  //     margin: 0 * $vgu;
  //   & + .hide-before::before
  //     display: none;

.navigation__secondary .navigation--link.hide-before::before
  display: none;

.card.selected-resource
  background-color: colorPalette('selected', 1);

.selected-resource
  background-color: darken($c--bg-sidebar__hover, 15%);
  &.navigation--link__active
    background-color: darken($c--bg-sidebar__hover, 25%);

.col-xs-12.no-left-padding
  padding-left: 0;

[long-touch]
  touch-action: pan-y;
  user-select: none;

.action-buttons
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3 * $vgu;
  flex: 1;
  background-color: $c--white;
  justify-content: space-evenly;

.bulk-action-buttons-padding
  padding-bottom: 11 * $vgu;
  +when(md)
    padding-bottom: 0;
