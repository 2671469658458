@require 'nib'
@require '_constants'
@require '_mixins'

// Flexbox grid

// First make sure every div is a flexbox (row by default).
div, .flex, section
  display: flex;
  &.introjs-tooltip, &.introjs-tooltipbuttons
    display: block;
div, span
  min-width: 0;

.row
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto // (browser default anyway);
  margin-left:  -1 * $colGutter;
  margin-right:  -1 * $colGutter;

  &.reverse
    flex-direction: row-reverse;

.flex-reverse-col
  display: flex;
  flex-direction: column;

for $val in 0 1
  .flex-{$val}
    flex: $val;
  .flex-grow-{$val}
    flex-grow: $val;
  .flex-shrink-{$val}
    // Important because it was there pre this change.
    flex-shrink: $val !important;

.flex-no-wrap
  flex-wrap: nowrap;

.width-100
  width: 100%;

for $pos in top left bottom right
  .vgu-{$pos}-margin
    margin-{$pos}: $vgu;
  .vgu-{$pos}-padding
    padding-{$pos}: $vgu;
  .no-{$pos}-margin
    margin-{$pos}: 0;
  .negative-vgu-{$pos}-margin
    margin-{$pos}: -1 * $vgu;

.vgu-margin
  margin: $vgu;
.negative-vgu-margin
  margin: -1 * $vgu;

// This handy function generates a single selector for col-[size]
// and all of col-[size]-[1-number]
colSelectors(size, number)
  $class = ".col-" + size;
  $selectors = $class;
  for i in 1..number
    $selectors += ", .col-" + size + "-" + i;
  return $selectors

.col-gutters
  padding-right: $colGutter;
  padding-left: $colGutter;

colStyles(name)
  //: Reset the flex properties of the col classes and give them all;
  //: the correct padding (no need to modify v-padding);
  {colSelectors(name, $numCols)}
    flex: 0 0 auto;
    padding-right: $colGutter;
    padding-left: $colGutter;
    flex-direction: column;

  //: Space filling column;
  .col-{name}
    // Assume that all items in the space filling columns would grow from nothing..
    flex-basis: 0%;
    flex-grow: 1;
    max-width: 100%;
    flex-direction: column;
    min-width: auto;

  .col-{name}__no-grow
    flex-grow: 0;

  .display-none-{name}
    display: none;

  .display-flex-{name}
    display: flex;

  .display-contents-{name}
    display: contents;

  .vertical-{name}
    flex-direction: column !important;

  .horizontal-{name}
    flex-direction: row !important;

  //: Align classes;
  .align-start-{name}
    align-items: flex-start;

  .align-center-{name}
    align-items: center;

  .align-end-{name}
    align-items: flex-end;

  .align-stretch-{name}
    align-items: stretch;

  .align-content-start-{name}
    align-content: flex-start;

  .align-content-center-{name}
    align-content: center;

  .align-content-end-{name}
    align-content: flex-end;

  .align-content-stretch-{name}
    align-content: stretch;

  //: Justify classes;
  .justify-around-{name}
    justify-content: space-around;

  .justify-between-{name}
    justify-content: space-between;

  .justify-evenly-{name}
    justify-content: space-evenly;

  .justify-start-{name}
    justify-content: flex-start;

  .justify-center-{name}
    justify-content: center;

  .justify-end-{name}
    justify-content: flex-end;

  .nowrap-{name}
    flex-wrap: nowrap;

  .wrap-{name}
    flex-wrap: wrap;

  .wrap-reverse-{name}
    flex-wrap: wrap-reverse;

  //: Positioning classes;
  .first-{name}
    order: -1;

  .last-{name}
    order: 1;

  .center-self-{name}
    align-self: center;

  .end-self-{name}
    align-self: flex-end;

  .start-self-{name}
    align-self: flex-start;

  //: Positional classes;

  .position-relative-{name}
    position: relative;

  .position-absolute-{name}
    position: absolute;

  .position-static-{name}
    position: static;

  .position-fixed-{name}
    position: fixed;

  .max-height-100-{name}
    max-height: 100%;
    +browser(edge)
      max-height: auto;

  for i in 1..$numCols
    //: Width of all columns (box w/ padding);
    $colWidth = (100% / $numCols) * i

    //: Fixed width columns;
    .col-{name}-{i}
      flex-basis: $colWidth;
      max-width: $colWidth;

    //: Offset classes;
    if i < $numCols
      .col-{name}-offset-{i}
        margin-left: $colWidth;

  .basis-0-{name}
    flex-basis: 0;
    +browser(safari)
      flex-basis: 0%;
  .basis-100-{name}
    flex-basis: 100%;
  .basis-auto-{name}
    flex-basis: auto;

  .cross-axis-full-height-{name}
    height: 0;
    min-height: 100%;
    +browser(safari)
      height: auto;
    +browser(edge)
      height: auto;
      min-height: 0;

.min-height-0
  min-height: 0;

for breakPointName, minWidth in $breakpoints
  if !minWidth
    colStyles(breakPointName)
  else
    @media only screen and (min-width: minWidth)
      colStyles(breakPointName)

  //: define block mixins (e.g.) when(sm);
  when(bp)
    if $breakpoints[bp]
      @media only screen and (min-width: $breakpoints[bp])
        {block}
    else
      @media only screen and (min-width: bp)
        {block}

// Slide preview
.flex-basis-auto
  flex-basis: auto;
.flex-basis-0
  flex-basis: 0;
  +browser(safari)
    flex-basis: 0%;
