@require '_colours'

.ct-series-a .ct-slice-donut
    stroke: colorPalette('accent', 2);

.ct-series-b .ct-slice-donut
    stroke: $c--bg-sidebar__hover;

.ct-series-a .ct-bar
  /* Colour of your bars */
  stroke: colorPalette('accent', 1);
  /* The width of your bars */
  // NOTE: make specific to horizontal if width-based changes.
  // +when(md)
  //   stroke-width: 2 * $vgu;
  // +when(md)
  //   stroke-width: 4 * $vgu;

.chartist__angled-x-labels
  .ct-label.ct-horizontal, .ct-label.ct-horizontal.ct-end

    transform-origin: top left;

    justify-content: flex-start !important;
    text-align: left !important;
    align-items: flex-start !important;
    transform: translateX(50%) rotateZ(90deg);
    // width: 3 * $vgu !important
    +when(md)
      transform: translateX(50%) rotateZ(45deg);


pxn-gauge-chart
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  min-height: 13em; // For Edge and Firefox - No height, wraps w/ other elems.

  h1
    margin: 0;

  chartist
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
