@font-face
  font-family: 'creepster';
  src: url('../fonts/creepster.ttf');
  font-weight: 400;

.halloween
  #content:before
    width: 30em;
    height: 30em;
    content: " ";
    background-image: url('../img/spider-web.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    opacity: 0.1;
    pointer-events: none;

  .profile-block
    background-color: $c--halloween-orange;
    background-image: url('../img/profile-blocks/halloween.jpg');
    background-size: cover;
    background-position: bottom center;


    pxn-button
      button
        background-color: transparent;
        &:before, &:active:not(:disabled)
          background-color: darken($c--halloween-orange, 8);

    gravatar-image
      border: 0.5 * $vgu solid $c--halloween-orange__compl;
      background-size: 120%;
      background-position: center;

    &--name
      color: $c--halloween-orange__compl;
      margin: 0;
      padding: 0 0.5em;
      line-height: 2em;
      flex: 0 0 auto;
      font-weight: 700;
      background-color: rgba(0,0,0,0.5);
      padding-top: 0.5em;
      margin-bottom: -0.5 * $vgu;

    &--company
      font-family: 'creepster';
      font-size: 1.5em;
      color: $c--halloween-orange;
      margin-top: 0.5 * $vgu;
      background-color: rgba(0,0,0,0.5);
      padding: 0.5 * $vgu;
      padding-top: 0.75 * $vgu;
      &__delegate
          color: lighten($c--halloween-orange, 25);
  .user-list--body, .activity--visual
    gravatar-image
      border: 0.25 * $vgu solid $c--halloween-orange__compl;
      background-size: 120%;
      background-position: center;


@font-face
  font-family: 'christmas';
  src: url('../fonts/christmas.ttf');
  font-weight: 400;

.christmas
  .profile-block
    background-color: $c--christmas-red;
    background-image: url('../img/profile-blocks/christmas.jpg');
    background-size: cover;
    background-position: bottom center;

    pxn-button
      button
        background-color: transparent;
        &:before, &:active:not(:disabled)
          background-color: $c--christmas-red;

    gravatar-image
      border: 0.5 * $vgu solid $c--christmas-red;
      background-size: 115%;
      background-position: center;

    &--company
      font-family: 'christmas';
      font-size: 1.5em;
      color: $c--christmas-red;

    &--name
      background-color: $c--christmas-red;
      flex: 0 0 auto;
      margin-bottom: $vgu;
      padding: 0 0.5 * $vgu;

    &--company
      align-items: center;
      display: flex;

    &--company, &--name
      &__delegate
        font-weight: 700;
        background-color: $c--christmas-white;
        padding: 0.5 * $vgu;

  .christmas-preview
    display: block;
    &--preview
      background-image: url('../img/merry-xmas.gif')
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

  $globe-width = 12;
  $globe-width-px = 12px;
  $globe-height = 28;
  $globe-height-px = 28px;
  $globe-spacing = 40;
  $globe-spacing-px = 40px;
  $globe-spread = 3px;
  $light-off-opacity = 0.4;
  $light-rope-height = -16px;

  .lightrope
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: $z-index__floating-content;
    margin: -16px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
    li
      position: relative;
      animation-fill-mode: both;
      animation-iteration-count:infinite;
      list-style: none;
      padding: 0;
      display: block;
      width: $globe-width-px;
      height: $globe-height-px;
      border-radius: 50%;
      margin: ($globe-spacing / 2)px;
      display: inline-block;
      background: rgba(0,247,165,1);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(0,247,165,1);
      animation-name: flash-1;
      animation-duration: 2s;
      &:nth-child(2n+1)
        background: rgba(0,255,255,1);
        box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(0,255,255,0.5);
        animation-name: flash-2;
        animation-duration: 1.4s;
      &:nth-child(4n+2)
        background: rgba(247,0,148,1);
        box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(247,0,148,1);
        animation-name: flash-3;
        animation-duration: 4.1s;
      &:nth-child(odd)
        animation-duration: 3.8s;
      &:nth-child(3n+1)
        animation-duration: 2.4s;
      &:before
        content: "";
        position: absolute;
        background: #222;
        width: ($globe-width - 2)px;
        height: ($globe-height/3)px;
        border-radius: 3px;
        top: (0 - ($globe-height/6))px;
        left: 1px;
      &:after
        content: "";
        top: (0 - ($globe-height/2))px;
        left: ($globe-width - 3)px;
        position: absolute;
        width: ($globe-spacing + 12)px;
        height: ($globe-height/3 * 2)px;
        border-bottom: solid #222 2px;
        border-radius: 50%;
      &:last-child:after
        content: none;
      &:first-child
        margin-left: -1 * $globe-spacing-px;

  @keyframes flash-1 {
      0%, 100% { background: rgba(0,247,165,1);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(0,247,165,1);}
      50% { background: rgba(0,247,165,$light-off-opacity);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(0,247,165,0.2);}
  }
  @keyframes flash-2 {
      0%, 100% { background: rgba(0,255,255,1);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(0,255,255,1);}
      50% { background: rgba(0,255,255,$light-off-opacity);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(0,255,255,0.2);}
  }
  @keyframes flash-3 {
      0%, 100% { background: rgba(247,0,148,1);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(247,0,148,1);}
      50% { background: rgba(247,0,148,$light-off-opacity);
      box-shadow: 0px ($globe-height/6)px ($globe-width*2)px $globe-spread rgba(247,0,148,0.2);}
  }

// Christmas profile images from https://www.flaticon.com/
// merry xmas gif generatefd from https://codepen.io/chrisgannon/pen/VeaBVv
// xmas lights from https://codepen.io/tobyj/pen/QjvEex
// xmas profile block by Natalia Koroshchenko from Pixabay https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2136768
