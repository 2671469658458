@require '_utility'
@require '_colours'
@require '_inputs'

pxn-tag-input
  display: block;
  min-height: 2 * $vgu;

&.disabled // This is not an input so input styling isn't applied
  div
    background-color: #aaa;
    color: #ccc;
    border: none;

.tag-input
  display: flex;
  max-width: 100%;
  flex-flow: row wrap;
  &__editable
    width: 100%;
    background-color: $c--bg-input;
    margin: -0.5 * $vgu;
    margin-bottom: -0.5 * $vgu;
    padding: "calc(%s - %s)" % (0.5 * $vgu 2px) 0.5 * $vgu;
  &__actionable
    cursor: pointer;
  &__disabled
    background-color: $c--txt-link__subtle;


  /pxn-tag-input
    &[editable="true"]
      .tag-input__editable
        margin: 0;
        inputborder();
    &.__no-neg-margin[editable="toggle"]
      margin: 0.5*$vgu;

  &__focussed&__editable
    // Important needed to override the fact this is a non-focussed input.
    border-color: $c--border-focus !important;

  &--tag-list
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    overflow: hidden;

  &--tag
    display: flex;
    overflow: hidden;
    font-size: 0.8rem;
    flex: 0 1 auto;
    height: "calc(%s - %s)" % (2 * $vgu 1px);
    padding: 0 $colGutter;
    margin-right: 1px;
    border: 1px solid;
    fullColorPalette('accent', 2)
    margin-bottom: 1px;

    &__selected
      background-color: colorPalette('accent', 3);

  &--content
    position: relative;
    top: -1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;


  &--input
    background-color: transparent;
  &--input-container
    height: "calc(%s - %s)" % (2*$vgu 1px);
    margin: 0 !important;
  &--input, &--input-container
    display: flex;
    border: 0;
    min-width: 3.5rem;
    height: 2 * $vgu;
    flex: 1 1 0;
    padding-left: 2px;
    margin-bottom: 1px;

    &:focus
      outline: 0;

  &--button-container
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;

  &--delete-button
    padding: 0;
    padding-right: $colGutter;
    &:after
      line-height: "calc(%s - %s)" % (2*$vgu 2px);
      position: relative;
      display: inline-block;
      font-family: entypo;
      content: '\2715';
      font-size: 0.75rem;
      left: 0.5 * $vgu;
      top: -0.1rem;

  //: When tag inputs are used for filter bars;
  &__filter
    .tag-input
      &--content
        top: -2px;

      .autocomplete
        &--container
          border: 0;

        &--suggestion
          background-color: $c--white;
          &__selected
            background-color: $c--pixel-purple;
