@require '_constants'
@require '_animations'

.dashboard-container
  +when(md)
    height: 0;
    min-height: 100%;
    +browser(safari)
      height: unset;
      .change-log
        .card
          flex-shrink: 0;

.change-log
  &--card
    flex: 0 1 auto;

.change-log-image
  &--card
    width: 100%;
    height: auto;
    padding-bottom: $vgu;

.activity
  position: relative;
  &--card
    flex: 1 0 auto;
    // +when(md)
    +when(lg)
      min-height: 0;
      flex-shrink: 1;
      padding-bottom: $vgu;
      flex-basis: 0;
      +browser(safari)
        min-height: 0%;
  &-col
    // height: 0;
    // min-height: 100%;
  &-items
    margin-top: $vgu;
    flex-direction: column;
    pxn-activity-item:nth-child(15)
      margin-bottom: 0;
  &--visual
    position: relative;
    flex-shrink: 0;
    margin-right: $vgu + $colGutter;

  &--icon-bubble
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: $c--white;
    border-radius: 50%;
    width: 2 * $vgu;
    height: 2 * $vgu;
    top: 3.5 * $vgu;
    right: -0.75 * $vgu;

  &--icon
    font-style: normal;
    &:before
      color: colorPalette('accent', 2);
      font-size: 1rem;

  &--action-button
    margin-left: $vgu;

  &--timeago
    top: $vgu;
    right: $vgu;
    text-align: right;
    margin-left: $vgu;

  &--details
    .target-name
      // word-break: break-word;
      font-weight: bold;
      color: colorPalette('grey', 2)
      &__clickable
        cursor: pointer;
        color: colorPalette('accent', 2);

    &__clickable span
      cursor: pointer;

  &--filter-icon
    cursor: pointer;
    flex: 0 1 auto;
    transition: color $anim-duration;
    color: $c--txt-button ;
    &__active
      color: $c--txt-main;
    &:before
      font-size: 1rem;
      +when(sm)
        //
      +when(md)
        //
      +when(lg)
        //

.login-attempt-list
  &--attempt
    &__successful
      color colorPalette('positive', 1)
    &__failed
      color colorPalette('negative', 1)

.changelog
  &--category
    margin-left: $vgu;
    margin-bottom: $vgu;

  &--sublist
    margin-top: $vgu;

  &--item
    margin-left: 3*$vgu;
    margin-bottom: $vgu;
    list-style-type: disc;

  &--note
    margin-left: 0.5 * $vgu;
    border-left: 0.5 * $vgu solid colorPalette('neutral', 3);
    padding-left: 1 * $vgu;
    margin-bottom: $vgu;
    font-style: italic;
    color: colorPalette('neutral', 2)

  &--video
    position: absolute;
    top: $vgu;
    height: "calc(%s - %s)" % (100% ($vgu * 2));
    width: "calc(%s - %s)" % (100% ($vgu * 2));
    &-container
      position: relative;


.account-health
  &--status
    width: 6 * $vgu;
    height: 6 * $vgu;
    margin-bottom: $vgu;

    border-radius: 10%;
    position: relative;
    justify-content: center;
    align-items: center;
    align-self: center;

    flex-shrink: 0;


    transition: background-color $anim-duration;

    &:before
      display: block;
      font-family: 'entypo';
      color: white;
      font-size: 2rem;

    &__loading
      background-color: colorPalette('accent', 3);
      &:before
        content: "\27f3";
        position: relative;
        left: 0.15 * $vgu;
        transform-origin: 43.5% 50%;
        animation: zAxisSpin 1s infinite linear


    &__good
      background-color: colorPalette('positive', 1);
      &:before
        content: "\2713";
    &__warn
      background-color: colorPalette('warning', 1);
    &__critical
      background-color: colorPalette('negative', 1);
    &__warn, &__critical
      &:before
        content: "\26a0";
        position: relative;
        top: -0.2 * $vgu;
      &:before
        content: "\26a0";
        position: relative;
        top: -0.2 * $vgu;

  &--message
    margin-left: $vgu;

    &__loading
      // justify-content: center;
      align-items: center;
      margin-bottom: $vgu;
      padding-left: $vgu;

    &-head
      margin-bottom: 0;

.slide-approvals
  flex: 0 0 auto;
  flex-direction: column;
  &--heading
    align-items: center;
    flex: 0 0 auto;
  &--slides
    flex: 0 1 auto;
    flex-direction: column;
  &--no-slides
    text-align: center;
  &--slide
    flex: 0 0 auto;
    margin-bottom: $vgu;
    &__last
      margin-bottom: 0;
    .card
      flex: 0 0 100%;
      margin-bottom: 0;
      padding-bottom: $vgu;
      pxn-button
        align-self: flex-end;
  &--meta
    justify-content: space-between;
    color: $c--txt-main;
    padding: 0 $vgu 0 0;

  .tab
    user-select: none;
    cursor: pointer;
    background-color: #AFAFAF;
    &-selected
      background-color: $c--txt-tooltip;



  // .approval-slide
  //   padding-bottom: $vgu;
  //   align-items: center;
  //   flex: 1 0 auto;
  //   pxn-button
  //     display: block;
  // &--meta
  //   justify-content: space-between;
  //   padding: 0 (2 *$vgu) 0 0;
  // &--owner
  //   color: $c--txt-main;
  // &--when
  //   font-size: 1.125 * $vgu;
  // &--wrapper
  //   flex-direction: column;;
