// When the buttons have a bottom border, we need to adjust
// their bottom margin to ensure that the baseline grid alignment
// for subsequent elements is maintained.
@require '_responsive-grid'
@require '_utility'
@require '_colours'

@keyframes icon-spin
  0%
    transform: rotate(0);
    transform-origin: center;
  100%
    transform: rotate(-360deg);
    transform-origin: center;

$button--bottom-border-px = 2px

// Make our directive wrappers flexboxes,
// so we can fill the available space.
pxn-button
  display: flex;
  flex: 0 0 auto;

  &.disabled
    pointer-events: none;
    user-select: none;

// This gets added to the button shine element when it's clicked.
@keyframes button-shine
  0%
    transform: translateX(50%) rotateZ(45deg);
    opacity: 0;
  50%
    opacity: 1;
  100%
    transform: translateX(-50%) rotateZ(45deg);
    opacity: 0;

// Reset browser styles that we never want to use
button
  background-color: transparent;
  border: 0;
  padding: $vgu 1.5*$vgu;
  outline: 0;
  color: inherit;
  cursor: pointer;

// We don't want the above padding on color picker buttons (swatches)
.pcr-swatches
  button
    padding: unset;

$directions = 'top' 'right' 'bottom' 'left'

pxn-button
  margin-bottom: $vgu;

  for direction in $directions
    &.no-{direction}-margin
      margin-{direction}: 0 !important;

  &.half-bottom-margin
    margin-bottom: 0.5 * $vgu;

  &.pxn-button__highlight
    .bg-accent-1
      background-color: $c--accent-2;
      contrastingText($c--accent-2)
      border-color: pastelise($c--accent-2);

  // &[type="naked icon"]
  //   margin-bottom: 0 !important;

.pxn-resource-grouper
  margin-bottom: 0;

.button
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex: 1;
  border-width: 0;
  border-bottom-width: $button--bottom-border-px;
  border-bottom-style: solid;
  margin-top: 0;
  padding-bottom: "calc(%s - %s)" % ($vgu $button--bottom-border-px / 2);
  padding-top: "calc(%s - %s)" % ($vgu $button--bottom-border-px / 2);
  font-weight: bold;
  font-family: $font-headings;

.button, .button__icon--text span, .button__icon--icon-container,
.button__icon--container
  transition: background-color $anim-duration, color $anim-duration;

  &:focus
    outline: 0;

  &:focus:not(:hover)
    outline: thin dotted black;

  &:before
    display: block;

  //: This is the shine inside the button you see when you click;
  & .button--shine
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 50%;
    transform: rotateX(90deg);
    opacity: 0;
    background-color: #fff3;
    animation-name: none;
    animation-duration: 0.5s;
    transform-origin: bottom;

    &.clicked
      animation-name: 'button-shine';

  //: Button text.;
  & .button--text
    z-index: 2;
    content: attr(data-content);
    user-select: none;

  // Small buttons
  &__small
    font-size: 0.8rem;
    line-height: 2 * $vgu;
    padding-bottom: "calc(%s - %s)" % (0.5 * $vgu $button--bottom-border-px / 2);
    padding-top: "calc(%s - %s)" % (0.5 * $vgu $button--bottom-border-px / 2);

    &.button__naked-icon
      width: 3 * $vgu;
      height: 3 * $vgu;
      padding: 0;
      &:before
        font-size: 1rem;

    .button__icon--text
      padding-bottom: "calc(%s - %s)" % (0.5 * $vgu $button--bottom-border-px / 2);
      padding-top: "calc(%s - %s)" % (0.5 * $vgu $button--bottom-border-px / 2);

    .button__icon--icon-container
      width: 3 * $vgu;

    .button__icon--icon:before
      font-size: 1rem;
      line-height: 2 * $vgu;
      top: 1px;

  &__tiny
    margin-bottom: 0;
    font-size: 0.6rem;
    padding: 0 $colGutter;
    text-transform: uppercase;
    .button--text
      span
        line-height: "calc(%s - %s)" % ($line-height-body $button--bottom-border-px);

    &.button__icon
      height: 2 * $vgu;

    .button__icon--text
      // padding: "calc(%s - %s)" % (0.5 * $vgu $button--bottom-border-px / 2) $colGutter;
      padding: 0 $colGutter;
      margin-top: -2 * $button--bottom-border-px
      height: 2 * $vgu
      align-self: flex-end;

    .button__icon--icon-container
      height: 2 * $vgu;
      width: 2 * $vgu;

    .button__icon--icon:before
      font-size: 0.8rem;
      line-height: 2 * $vgu;
      top: 1px;

    .button__icon--icon
      &.entypo-cancel:before
        top: 0px

    / pxn-button[modifiers="tiny"]
      margin-right: $colGutter;
      &:last-of-type
        margin-right: 0;

  &__responsive
    .button__icon--text
      display: none;

      +when(sm)
        display: flex;

    for $breakPointName in $breakpoints
      &-{$breakPointName}
        .button__icon--icon-container
          width: 3 * $vgu;
          height: 3 * $vgu;
        .button__icon--text
          display: none;
          flex: 1;

          +when($breakpoints[$breakPointName])
            display: flex;

  &__round
    padding: $vgu;
    border-radius: 50%;


  &__loading
    .button__icon--icon
      animation: icon-spin 0.5s infinite linear;

  &__shadowed
    padding: $vgu;
    border-radius: 50%;
    $shadow__emphasize();

  // This modifier stops the button actually receiving the click events, which is
  // useful for file uploads, but not much else.
  &__no-pointer
    pointer-events: none;

  &__reverse
    .button__icon--container
      flex-direction: row-reverse;

  &__filter
    padding: $vgu;
    position: relative;
    &:before
      position: relative;
      z-index: 2;
    &:after
      position: relative;
      z-index: 1;
      top: -1 * $vgu;
      display: block;
      font-family: 'entypo';
      font-size: 2.5rem;
      color: #eee;

  &__filter-up::after
      //: up arrow;
      content: '\2191';
  &__filter-down::after
      //: down arrow;
      content: '\2193';

  //: cancel button;
  &__close
    position: absolute;
    right: 0;
    top: 0;


.button__icon
  flex-direction: column;
  align-items: stretch;
  border-bottom-width: 0;
  padding: 0;

  //: This is required to allow buttons in the same row to stretch;
  //: to be the same height as each other.;
  &--container
    flex-direction: row;
    flex: 1;

  &--icon-container, &--text
    border-bottom-width: $button--bottom-border-px;
    border-bottom-style: solid;
    justify-content: center;

  &--text
    display: flex;
    margin-bottom: 0;
    padding: $vgu 1.5*$vgu;
    padding-bottom: "calc(%s - %s)" % ($vgu $button--bottom-border-px / 2);
    padding-top: "calc(%s - %s)" % ($vgu $button--bottom-border-px / 2);
    //: vertical align;
    align-items: center;
    transition: background-color 0.15s;
    flex: 1;

  &--icon-container
    width: 4 * $vgu;
    align-items: center;
    justify-content: center;

  &--icon
    &:before
      position: relative;
      //: Adjust so icons are a bit more central vertically.;
      top: $vgu * 0.2;
      margin: 0;

.button__naked-icon
  line-height: 0;
  &:before
    margin: 0;

.button__round-background-purple
  &:before
    display: block;
    border-radius: 50%;
    text-align: center;
    width: 3 * $vgu;
    height: 3 * $vgu;
    line-height: 3 * $vgu;
    transform: rotateY(180deg);
    font-size: 1.2rem;
    fullComplimentaryColorPalette('accent', 1)
    transition: background-color $anim-duration;

  &:hover
    &:before
      background-color: pastelise(colorPalette('accent', 1));

pxn-dropdown-menu
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin-bottom: $vgu;

.dropdown-menu
  &--button
    position: relative;
    margin-bottom: 0;
    // z-index: $z-index__content;
  &--items
    min-width: 100%;
    display: flex;
    position: absolute;
    right: 0;
    top: 3 * $vgu;
    background-color: $c--bg-sidebar;
    color: $c--white;
    margin: 0 0 $vgu
    transform: scaleY(0);
    transition: transform 0.2s;
    transform-origin: top;
    z-index: $z-index__content-overlay;
    font-family: $font-headings;
    font-size: 0.8rem;
    &__open
      transform: scaleY(1);

  &__tiny
    .dropdown-menu--items
      top: 2 * $vgu;

  &__menu
    &-up
      .dropdown-menu--items
        top: unset;
        bottom: 1.9 * $vgu;
        transform-origin: bottom;
    &-left // This is not finshed or used, keeping for reference.
      .dropdown-menu--items
        width: 19.4rem;
        .dropdown-menu--item-list
          flex-direction: row;
          flex-wrap: wrap;
          li:not(:last-child)
            flex: 1 0 50%;
          li:last-child
            flex: 1 0 100%;

  &__right
    .dropdown-menu--items
      right: auto
    .dropdown-menu--item
      flex-direction: row
      &-label
        justify-content: flex-start
    .button__icon--container
      flex-direction: row
  &--pointer
    li:first-child
      &:after
        content: "";
        position: absolute;
        top: -0.9 * $vgu;
        right: 0.8 * $vgu;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid #222;
        transition: 0.3s border-bottom-color ease;
        +browser(safari)
          height: unset;
      &:hover:after
        border-bottom-color: pastelise(colorPalette('neutral', 2));

  // TODO: give classes
  &--item-list
    display: flex;
    flex-direction: column;
    flex: 1;

  &--item
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    cursor: pointer;
    // align-items: center;
    &[disabled]
      & > .dropdown-menu--item-label, & > .dropdown-menu--item-icon
        color: rgba($c--shades['grey'][2],0.6);

    &-icon, &-label
      display: flex;
      border-bottom: solid 2px #444;
      align-items: center;
      justify-content: center;
      transition: background-color $anim-duration, border-color $anim-duration

    &-label
      display: flex;
      white-space: nowrap;
      padding: 0 $vgu;
      margin: 0;
      justify-content: flex-end;
      flex: 1;
      background-color: $c--bg-sidebar__dark;
      user-select: none;

    &-icon
      background-color: compliment($c--bg-sidebar__dark);
      border-color: compliment(compliment($c--bg-sidebar__dark));
      height: 3 * $vgu;
      width: 3 * $vgu;

      &:before
        font-size: 1.5 * $vgu;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5 * $vgu;
        height: 1.5 * $vgu;
        position: relative;

    // Hover style!
    &:hover
      background-color: $c--accent-1__compl;

      // .dropdown-menu--item:hover .dropdown-menu--item-icon
      & ^[1]-icon
        background-color: $c--accent-1;
        border-bottom-color: compliment($c--accent-1);

      & ^[1]-label
        background-color: $c--accent-1__compl;
        border-bottom-color: compliment($c--accent-1__compl);

    &__inactive
      color: $c--txt-button;
      & ^[1]-label
        background-color: disabledColour($c--bg-sidebar__dark);
        // border-bottom-color: disabledColour(compliment($c--bg-sidebar__dark));

      & ^[1]-icon
        background-color: disabledColour($c--bg-sidebar);
        // border-bottom-color: disabledColour(compliment($c--bg-sidebar));

      &:hover
        & ^[1]-icon
          background-color:  disabledColour($c--accent-1);
          border-bottom-color: disabledColour(compliment($c--accent-1));

        & ^[1]-label
          background-color:  disabledColour($c--accent-1__compl);
          border-bottom-color:  disabledColour(compliment($c--accent-1__compl));
    &__disabled
      pointer-events: none;

pxn-sortable-heading
  display: flex;
.sortable-heading
  &--icon
    &:before
      transition: transform $anim-duration;
      transform-origin: middle middle;
      margin-right: 0;
      margin-left: - 2 * $vgu;
    &__reverse
      &:before
        transform: rotateZ(180deg);

  &--heading
    noselect()
    margin-bottom: 0;

.page-overview-icon
  width: 20px;
  height: 0;
  padding: 0;
  border-top: 6 * $vgu solid transparent;
  border-left: 6 * $vgu solid transparent;
  border-right: 6 * $vgu solid darken(#7d0079, 15%);
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  +browser(safari)
    height: unset;
  & .entypo-help
    color: #fff;
    position: absolute;
    bottom: 0.7 * $vgu;
    left: 3.2 * $vgu;
  &:hover
    border-right: 6 * $vgu solid pastelise(colorPalette('accent', 1));

pxn-color-picker
  display: flex;
  flex-direction: row !important;
  line-height: 1.8;
  width: 100%;
  min-width: 100%;
  margin-bottom: $vgu;
  .pickr
    margin-right: $vgu;
  .pcr-button
    border: 1px solid colorPalette('grey', 4);
  .color-picker--value
    font-weight: 700;
    cursor: pointer;
  .color-picker--value::before
    content: attr(data-model-value);
    position: relative;
    top: 2px;

[pxn-disabled-reason]
  width: 100%;
