@require '_animations'
@require '_responsive-grid'

noselect()
  user-select: none;
  cursor: default;

// This function is useful when you want to transition on adding a background image. In order
// to transition, both values need to be images in the first place. The below is a transparent
// gif encoded in a data url.
transparentImageBackground()
  background-image unquote("url(data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==)");

.no-select
  noselect()

.cursor-pointer
  cursor: pointer;

.ellipsis
  overflow: hidden;
  text-overflow: ellipsis;

aspect-ratio(width, height)
  position: relative;
  display: block;
  .aspect-ratio__content
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
  &:before
    position: relative;
    z-index: -1;
    display: block;
    content: "";
    // width: 100%;
    padding-top: (height / width) * 100%;
    pointer-events: none

.low-opacity
  opacity: 0.25;

.fill-parent
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;

.aspect
  &__16-9
    aspect-ratio(16,9)
  &__4-3
    aspect-ratio(4,3)
  &__1-1
    aspect-ratio(1,1)

.cover-image, .contain-image
  background-position: center;
  background-repeat: no-repeat;
.contain-image
  background-size: contain;
.cover-image
  background-size: cover;

[pxn-image-preload]
  transition: background-image $anim-duration ease-in-out;


.image__loading
  align-items: center;
  justify-content: center;
  &:after
    color: white;
    animation: zAxisSpin 1s infinite linear;
    text-align: center;
    display: flex;
    font-family: 'entypo'
    font-size: 1.5em
    content: '\1f504'
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

.four-oh-four
  font-size: 16em;
  font-weight: bold;
  line-height: 1em;
  background-color: #bcbcbc;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255,255,255,0.5) 0px 4px 4px;
  +when(md)
    font-size: 20em;

  &__subtext
    font-size: 1.2em;
    color: #999;

.unsubscribed-message
  font-size: 1.2em;
  color: #999;
  text-align: center;

.json--pre
  font-family: monospace

.cursor
  &--grab
    cursor: grab;
