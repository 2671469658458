.accordion
  &--heading, &--heading-title, &--heading-buttons, accordion-title, accordion-buttons
    display: flex;
    flex-direction: row;
  &--heading
    cursor: pointer;
    &.forced-open
      cursor: not-allowed;
  // `&-heading--icon` is for pxn-permissions-leaf directive
  &--status-icon, &-heading--icon
    position: relative;
    margin-bottom: $vgu;
    align-self: center;
    flex: 0 0 auto;
    &::before
      display: inline-block;
      transform: rotate(-90deg);
      transition: transform $anim-duration;
      font-family: 'entypo';
      content: "\e75c";
    &__open
      &::before
        transform: rotate(0deg);
  &--heading-title, accordion-title
    flex: 1 1 auto;
    overflow: hidden;
  &--heading-buttons
    flex: 0 0 auto;

  &--body, accordion-body
    flex-direction: column;
