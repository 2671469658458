@require '_constants'
@require '_colours'

pxn-period-input.ng-invalid input
  border-color: $c--border-negative;

  // &:disabled
  //   background-color: colorPalette('disabled', 1);
  //   color: colorPalette('disabled', 2);

pxn-period-input.new-entry:not(:focus-within) input
  color: $c--txt-tooltip;

.period-input
  &--container
    // styles for container
  &--input
    // styles for inputs
    &:first-of-type
      margin-right: 0.5 * $vgu;
    &:last-of-type
      margin-left: 0.5 * $vgu;

.schedule-table
  width: 56 * $vgu

  &--header
    text-align: left;

  &--day-selector__margin
    .days
      margin-bottom: 0;

  &--element
    padding: 0.5 * $vgu;

  &--button__margin
    margin-bottom: 0;

  &--new-entry-row
    td
      border-top: thin solid #EDEDED;