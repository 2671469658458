$original-thumbnail-witdh = 192px;

.slide-container.row
  flex-direction: row-reverse;
  height: 0;

  +browser(firefox)
    flex-wrap: nowrap;

  .slide-helper
    flex-direction: column-reverse;

    +when(md)
      flex-direction: column;

    &--about
      overflow: hidden;
      flex: 0 1 auto;

    &--filters, &--about
      flex-direction: column;

  .slides
    &-list
      +when(md)
        flex: 1 1 0%;
        min-height: 0;

        +browser(safari)
          min-height: 0;

      +browser(edge)
        max-height: 100%;

      &--sort
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

      & .slide-library
        flex: 1;

        +browser(firefox)
          flex: 1 1 auto;

      &--no-results
        padding: 3em;
        align-self: center;
        text-align: center;

.slide
  flex-direction: row;
  margin-bottom: $vgu;
  flex: 0 0 auto;

  &-library
    flex: 1 1 auto;
    flex-direction: column;
    margin-bottom: $vgu;

  &__last
    margin-bottom: 0;

  &-controls
    &__filters
      flex: 0 0 auto;

  &--info-overlay
    pointer-events: none;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 (0.5 * $vgu);
    padding: (0.5 * $vgu) 0;
    background-color: colorPalette('accent', 1);
    justify-content: center;
    z-index: $z-index__content;
    color: $c--white;

    &&.invert-banner
      background-color: $c--white;
      color: colorPalette('accent', 1);
      border: 1px solid colorPalette('accent', 1);

      span
        color: colorPalette('accent', 1);

    span
      font-size: 1.2 * $vgu;
      color: $c--white;

    [class*='entypo-']:before
      margin-right: 0.7 * $vgu;
      font-size: 1.5 * $vgu;

  &--orientation-overlay
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0.2 * $vgu;
    padding: 0.2 * $vgu;
    justify-content: flex-end;
    z-index: $z-index__content;

    &__both
      span
        position: relative;

      span.entypo-doc
        right: 0;
        bottom: 0.5em;

      span.entypo-doc-landscape
        right: -1.2em;
        bottom: 0;

    span
      font-size: 1.2 * $vgu;
      color: $c--semi-t-white;

    [class*='entypo-']:before
      margin-right: 0.7 * $vgu;
      font-size: 1.5 * $vgu;

  &--title-overlay
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index__content;
    align-items: center;
    pointer-events: none;

    .title
      color: $c--white;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      text-align: center;
      margin: 0;
      padding: ($vgu * 0.5) $vgu;
      font-size: 0.6em;

      +when(sm)
        font-size: 0.8em;

      +when(md)
        font-size: 0.7em;

      +when(lg)
        font-size: 0.8em;

      +when(xl)
        font-size: 1.2em;

  &--approval-overlay
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: (0.5 * $vgu) 0;
    background-color: colorPalette('accent', 1);
    justify-content: center;
    z-index: $z-index__content;

    span
      font-size: 1.2 * $vgu;
      color: $c--white;

    [class*='entypo-']:before
      margin-right: 0.7 * $vgu;
      font-size: 1.5 * $vgu;

  &-edit
    flex: 0 1 auto;
    overflow: hidden;

    // &--scroll-elem
    // flex: 1 1 0;
    .auto-height-fieldset
      flex: 0 0 auto;

      .template-browser-suggestion
        flex: 1 0 auto;
        flex-direction: column;
        padding: 0 0.5 * $vgu;

        +when(sm)
          flex-direction: row;

        &--text
          flex: 1;
    &-info
      .slide-usage
        flex: 0 0 auto;
        &__info
          flex: 0 0 auto;
        .channel-list--container
          flex: 0 1 auto;
          flex-direction: column;
          .usage-card--channel-list
            flex: 0 0 auto;
            flex-wrap: wrap;
            padding-top: 0.5 * $vgu;
            justify-content: space-evenly;
            flex-direction: column;
            &__item
              flex: 0 0 auto;
              justify-content: space-between;
              align-items: stretch;
              width: 100%;
              cursor: default;
              margin: 0 0.5 * $vgu 0 0.5 * $vgu;
              padding-left: 0.5 * $vgu;
              &:not(:last-child)
                border-bottom: 1px solid #eee;
              &.shared-channel
                background-color: $c--black;
                content: '\1f465';
                color: $c--offwhite;
              &.already-modified, &.no-permission-to-update
                .channel-name, .channel-data--data__instance-count, .channel-data--data__repeater-count
                  color: $c--shades['disabled'][0];

              .grey-border-r
                box-sizing: border-box;
                border-right: 1px solid #eee;
                line-height: 2 * $vgu;
                justify-content: center;

              .channel-data
                &--name
                  flex: 0 1 auto;
                  margin: 0;
                  padding-top: 0.5 * $vgu;
                  .channel-name
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  .had-pending-changes:before
                    color: $c--shades['warning'][0]
                  share-indicator
                    margin-bottom: 0.4 * $vgu;
                &--data
                  flex: 0 0 auto;
                  flex-direction: row;
                  justify-content: flex-end;
                  align-items: center;
                  padding: 0;
                  margin-left: $vgu;
                  height: 3 * $vgu;
                  .channel-to-add .message , .channel-to-remove .message
                    font-style: italic;
                  &__instance-count, &__repeater-count
                    text-align: right;
                    display: flex;
                    align-self: stretch;
                    align-items: center;
                    padding-right: 0.5 * $vgu;
                    a
                      text-decoration: underline;
                      &.disabled
                        text-decoration: none;
                        color: inherit;
                  &__remove-from-channel
                    flex: 0 0 auto;
                    margin: 0;




    &--form-wrapper
      flex: 0 1 100%;

    &--form
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      flex-wrap: nowrap;

    &--action-panel
      flex: 1 0 auto;

    &--preview
      flex: 0 0 auto;
      margin-bottom: $vgu;
      background-color: $c--bg-preview;
      overflow: hidden;
      border: 1px solid #444;
      margin-left: -1px;
      box-sizing: border-box;

      &__portrait
        align-self: center;
        width: 50%;

      pxn-loading
        color: $c--offwhite;

    &--recurrence-fields
      flex-wrap: nowrap;

  &-approval
    margin-top: $vgu;

  &-image
    width: 100%;
    aspect-ratio(16, 9);
    background-color: $c--bg-sidebar__dark;
    background-repeat: no-repeat;
    background-size: cover;
    align-self: center;
    // For some reason the preload transition on this makes the images 'vibrate'.
    transition: none;
    // Hide any overflowing title text
    overflow: hidden;

    &--container
      position: relative;

    &.portrait-only
      background-size: contain;
      background-position: center;

  &-heading
    &--title
      align-items: center;

    &--name
      margin-right: $vgu;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    pxn-dropdown-menu
      // margin-bottom: $vgu;

.input
  &-splitter
    color: $c--txt-sidebar;

  &-container
    flex-direction: column;

.slide-recurrence
  margin-bottom: 'calc(%s - %s)' % ($vgu 1px);
  border-bottom: 1px solid colorPalette('grey', 5);

  &__last
    margin-bottom: $vgu;
    border-bottom: 0;

  &--delete
    // account for headings on other items.
    margin: 2 * $vgu $colGutter $vgu $colGutter;
    align-self: flex-start;

.lifetime-delete-button
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  +when(md)
    position: static;

  top: -3 * $vgu;

// Variables for checkered background
$cubeSize = 0.3 * $vgu;
$deepColor = #fff;

pxn-content-field-original
  blockquote
    border-left: 2px solid colorPalette(positive, 1);
    padding-left: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;

    .grid
      display: grid;
      grid-gap: 0.2 * $vgu;
      grid-auto-flow: row;
      grid-template-columns: max-content 1fr;

      .grid-row
        display: contents;

        .col-value
          word-wrap: break-word;

    .pxn-content-field-original
      &--color
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .checkered-background
          width: 2.5 * $vgu;
          height: 2.5 * $vgu;
          overflow: hidden;
          border: 1px solid #ccc;
          border-radius: 0.15em;
          background-color: #808080;
          background-image: linear-gradient(
            45deg,
            $deepColor 25%,
            transparent 25%,
            transparent 75%,
            $deepColor 75%,
            $deepColor
          ), linear-gradient(
            45deg,
            $deepColor 25%,
            transparent 25%,
            transparent 75%,
            $deepColor 75%,
            $deepColor
          );
          background-size: ($cubeSize * 2) ($cubeSize * 2);
          background-position: 0 0, $cubeSize $cubeSize;

          .watch
            width: 100%;
            height: 100%;
            border-radius: 0.15em;
            background-color: attr('background-color');

        strong
          margin-left: $vgu;

      &--thumbnail
        height: 108px;
        width: 192px;
        background-size: contain;
        background-repeat: no-repeat;

pxn-split-input
  display: flex;
  flex-direction: row;

  .input-container
    flex-grow: 1;
    flex-direction: column;

    .singleInput, .landscapeInput, .portraitInput
      flex-direction: column;

  .input-splitter
    color: $c--txt-sidebar;
    margin-left: $vgu;
    position: relative;
    top: 2.25 * $vgu;

    &__media
      margin-left: 0;

      &::before
        position: absolute;
        left: -2 * $vgu;

    &__hidden
      display: none;

  pxn-content-field
    width: 100%;

.card.selected
  background-color: colorPalette('selected', 1);

dir-pagination-controls > ul
  flex: 1;

.template-select
  width: 100%;

.no-before-margin
  &:before
    margin: 0;
