@require '_constants'
.media-cleaner
  &--pad-bottom
    height: 3 * $vgu;
  &--button-margin
    margin-right: $vgu;
  &--bold
    font-weight: 700;
  &--monospace
    font-family: monospace;
