.gravatar
  width: 5 * $vgu;
  height: 5 * $vgu;
  margin-bottom: $vgu;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  &__activity-feed
    position: relative;

  &__sidebar
    width: 7 * $vgu;
    height: 7 * $vgu;

  &__user-list
    width: 4 * $vgu;
    height: 4 * $vgu;
    border: 1px solid colorPalette('disabled', 1);

  &__manage-account
    width: 8 * $vgu;
    height: 8 * $vgu;
    margin-right: $vgu;
    align-self: flex-start;
