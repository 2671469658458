.wrapper
  flex-direction: column;
  flex: 1;
  max-height: "calc(100vh - %s)" % ($contentHeaderHeight + $vgu);
  +when(md)
    flex-direction: row-reverse;
  .bold
    font-weight: bold;
  .content-panel
    padding: 0;
    +when(md)
      padding-left: $vgu;
  .info-card
    padding: $vgu 0;
    flex: 0 0 auto;
    +when(md)
      flex: 0 1 auto;
    .template-info
      flex: 1 0 auto;
      flex-direction: column;
      padding: 0 $vgu;
      +when(md)
        flex: 0 1 auto;
      .card-top
        flex: 0 0 auto;
      .name-and-orientation
        flex-wrap: wrap;
        +when(sm)
          flex-wrap: nowrap;
        h2
          display: flex;
          margin: 0 $vgu;
          flex: 1 0 100%;
          justify-content: center;
          +when(sm)
            display: block;
            flex: 0 0 auto;
        .row.col-xs.space-filler
          flex-direction: row;
          flex-wrap: nowrap;
          padding: 0;
          flex: 1 0 auto;
          &__left
            margin: 0 0.5 * $vgu $vgu 0;
            order: 1;
            +when(sm)
              flex: 0 0 auto;
              order: 0;
          &__right
            margin: 0 0 $vgu 0.5 * $vgu;
            justify-content: flex-start;
            order: 2;
            flex: 1 0 auto;
            +when(sm)
              order: 0;
              justify-content: flex-end;
              flex: 0 0 auto;
        span
          position: relative;
        span.entypo-doc
          right: 0;
          bottom: -0.2em;
          &::before
            margin: 0;
          &.portrait-only
            margin-right: 0;
            bottom: -0.4em;
        span.entypo-doc-landscape
          right: -1.2em;
          bottom: -0.7em;
          &.landscape-only
            margin-right: 0.5 * $vgu;
            bottom: -0.4em;
      .description-and-latest-changes
        flex-direction: column;
        +when(md)
          flex-direction: row;
      .inner-wrapper
        flex: 0 1 auto;
        padding: 0 $vgu;
        padding-top: 0;
        flex-direction: column;
        h4
          margin-bottom: 0.5 * $vgu;
        &.image-slideshow-div
          margin-top: 2 * $vgu;
          flex-direction: column;
          +when(lg)
            flex-direction: row;
        .template-info--main-image
          background-size: auto 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-color: rgba(#ddd, 0.3);
          flex-grow: 1;
          width: 100%;
          height: 100%;
          align-self: center;
          padding: 0;
          transition: background-image 0.2s ease-in-out;
          &.no-bottom-margin
            margin-bottom: 0;
        &.description
          text-align: center;
          flex: 1 1 auto;
          padding: 0 $vgu;
          +when(sm)
            text-align: left;
          +when(md)
            flex: 1 1 0%;
        .template-info--description
          flex: 1 1 auto;
        &.latest-changes
          flex: 1 1 auto;
          li
            list-style: disc outside;
            margin-left: 1.6 * $vgu;
          +when(md)
            flex: 1 1 0%;
      .template-icons
        &--feeds, &--categories, &--features
          &:not(:last-child)
            margin: $vgu 0;
            border-right: 1px solid grey;
          &__item
            flex-direction: row;
            padding: 0.5 * $vgu $vgu;
            user-select: none;
            &::before
              margin: 0;
            &.pxn-icon-workplace:hover
              opacity: 1;

      h2.template-info--name
        flex: 1 1 auto;
        width: 100%;
        +when(sm)
          flex: 1 0 0;
          width: auto;
      .experimental-indicator
        height: 3 * $vgu
        margin-left: $vgu;
        font-weight: bold;
        color: $c--white;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 0 $vgu;
        background: #87CEFA;
        background: radial-gradient(darken(#87CEFA, 50%) 0%, #87CEFA 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        font-size: 0.8rem;
        border-radius: $vgu;
      &--button
        flex: 0 0 auto;
        width: 100%;
        +when(sm)
          padding-left: 2 * $vgu;
          margin-right: $vgu;
          width: unset;
          padding-left: 0;
        & pxn-button, & pxn-dropdown-menu
          margin: 0;
          &.remove-from-account
            margin-left: $vgu;
  .filters-panel
    padding: 0;
    overflow: hidden;
    flex: 0 0 auto;
    width: 100%;
    +when(md)
      margin-left: $vgu;
      max-height: "calc(100vh - %s)" % (7 * $vgu);
    .filters-card
      +browser(firefox)
        flex: 0 1 auto;
      +browser(edge)
        flex: 0 1 auto;
  .template-browser-filters
    width: 100%;
    align-self: flex-start;
    flex-direction: column;
    flex: 1;
    .row.orientation-filter
      min-height: 0;
      padding: 0.2 * $vgu;
      user-select: none;
      +browser(edge)
        justify-content: space-around;
      & .landscape, & .portrait
        flex-direction: row;
        border-radius: 3px;
        padding: 0.6* $vgu;
        cursor: pointer;
        &::before
          margin-right: $vgu;
    .filter-group
      flex: 0 1 auto;
    .filter-field
      flex-direction: column;
      flex: 0 0 auto;
      &__no-margin
        margin: 0;
      &.title
        flex-direction: row;
      .items-container
        display: grid;
        grid-gap: 0.5 * $vgu;
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fit, 10 * $vgu);
        user-select: none;
        margin-left: 0.5 * $vgu;
        +browser(edge)
          justify-content: space-around;
        overflow: hidden;
        &__item
          box-sizing: border-box;
          align-items: center;
          padding: $vgu 0.1 * $vgu;
          flex-direction: column;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
          border: 1px solid transparent;
          &::before
            margin: 0;
          &.selected-item
            border: 1px solid $c--black;
          &.selected-item, &:hover
            background-color: colorPalette('selected', 1);
            border-radius: 3px;
            color: inherit;
            opacity: 1;
          &:active
            background-color: colorPalette('grey', 5);
    .compensate-scrollbar.scrollbar__active
      margin-right: -1.5 * $vgu;
    .my-feeds
      flex-direction: column
      &--label
        margin-bottom: 0.5 * $vgu;
    .input-label
      font-weight: bold;
    .matching-templates
      margin: $vgu 0;
