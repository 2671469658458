.template-admin
  &--template
    &-path
      font-size: 0.8rem;

  &--usage:not(.last-xs)
    order: -1;
  &--migrate-delete
    order: 1;
    +when(xl)
      order: 0;
  &--list-item
    padding: 0.5 * $vgu $vgu;
    &.even
      background-color: #ddd;
.template-overview
  &--info
    th
      font-weight: bold;
      text-decoration: underline;
      &.centered
        width: 15 * $vgu;
    th, td
      padding: 0 $vgu;
      &.centered
        text-align: center;
        .checkbox
          width: 3 * $vgu;
          margin: auto;
      &:not(:last-child)
        border-right: 1px solid #999;
    li
      list-style-type: disc;
      list-style-position: inside;
